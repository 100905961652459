import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import PieChartIcon from "@mui/icons-material/PieChart";
import { TabContext, TabPanel } from "@mui/lab";
import {
  AppBar,
  Box,
  Breadcrumbs,
  Divider,
  Grid2,
  LinearProgress,
  Link,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import BarChartIcon from "@mui/icons-material/BarChart";
import makeStyles from "@mui/styles/makeStyles";
import { isEqual } from "lodash";
import ContentWrapper from "../components/ContentWrapper";
import ExportCsv from "../components/ExportCsv";
import FilterNav from "../components/FilterNav";
import NetworksAreaChart from "../components/NetworksAreaChart";
import NetworksPieChart from "../components/NetworksPieChart";
import NetworksTreeChart from "../components/NetworksTreeChart";
import NoResults from "../components/NoResults";
import PageHeading from "../components/PageHeading";
import SaveSearch from "../components/SaveSearch";
import TableAdvanced from "../components/TableAdvanced";
import { networksCells } from "../constants";
import allRoutes from "../constants/routes";
import { useSaveSearchMutation } from "../store/apis/save";
import { useGetPrivateNetworksQuery, useLazyExportPrivateNetworksQuery } from "../store/apis/search";
// import { selectSaveToken } from "../store/slices/auth";
import { selectPrivateNetworksSlice, setPage, setRowsPerPage, setSavedValues, setSortBy } from "../store/slices/privateNetworks";
import { selectUiState, setDense, setFontSize } from "../store/slices/ui";
import { mapValuesToSaveData } from "../utils";
import { selectExport } from "../store/slices/auth";

const useAppBarStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "white",
  },
  positionSticky: {
    [theme.breakpoints.up("lg")]: {
      top: 52,
    },
  },
}));

const PrivateNetworksSearch: React.FC = () => {
  const dispatch = useDispatch();
  const appBarStyles = useAppBarStyles();
  const { dense, fontSize, exportFields } = useSelector(selectUiState);
  const { sortBy, sortDesc, page, rowsPerPage, values, lastSaved } = useSelector(selectPrivateNetworksSlice);
  const exportEnabled = useSelector(selectExport);
  // const token = useSelector(selectSaveToken);
  const [format, setFormat] = useState("chart_region");
  const [trigger, { data: download, isLoading: isExporting }] = useLazyExportPrivateNetworksQuery();
  //  const theme = useTheme();
  const location = useLocation();
  const lastPart = location.pathname.split("/").pop() || "search";

  const [viewCurrent, setView] = useState(lastPart);
  const [saveSearch, { isLoading: isSaving }] = useSaveSearchMutation();

  const {
    data = { results: [], total: 0 },
    isLoading,
    isFetching,
    isUninitialized,
  } = useGetPrivateNetworksQuery({
    rowsPerPage,
    page,
    values,
    sortBy,
    sortDirection: sortDesc ? "desc" : "asc",
    format: lastPart === "pie" ? format : "json",
  });

  const { results = [], total = 0 } = data;
  const loading = isLoading || isFetching || isUninitialized;
  const pages = Math.ceil(total / rowsPerPage);

  let firstLoad = true;
  useEffect(() => {
    if (lastPart !== viewCurrent && !loading) {
      setView(lastPart);
    }
  }, [results, loading, lastPart, viewCurrent]);

  // if (firstLoad) {
  // dispatch(resetValues());
  // }

  const handleSave = () => {
    dispatch(setSavedValues(values));
    saveSearch({ sectionId: 3, data: mapValuesToSaveData(3, values) });
  };

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up("sm"));

  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue === "pie") {
      navigate(`${allRoutes.privateNetworks.path}/search/pie`);
    } else if (newValue === "area") {
      navigate(`${allRoutes.privateNetworks.path}/search/area`);
    } else if (newValue === "tree") {
      navigate(`${allRoutes.privateNetworks.path}/search/tree`);
    } else if (newValue === "search") {
      navigate(`${allRoutes.privateNetworks.path}/search`);
    }
  };

  const renderButtons = () => (
    <Box paddingY={4}>
      <Grid2 container spacing={4} alignItems="center">
        <Grid2>
          <SaveSearch disabled={isEqual(values, lastSaved)} loading={isSaving} onClick={handleSave} />
        </Grid2>
        <Grid2>
          <ExportCsv
            disabled={exportEnabled ? false : total > 2000}
            source={download}
            loading={isExporting}
            onClick={() => trigger({ values: values, fields: exportFields })}
          />
        </Grid2>
      </Grid2>
    </Box>
  );

  return (
    <Box width="100%">
      <Helmet title="Networks - GSA GAMBoD" />
      <FilterNav />
      <PageHeading
        title={allRoutes.privateNetworks.label}
        icon={allRoutes.publicNetworks.icon}
        color={allRoutes.publicNetworks.color}
        section="nts"
        inset
      >
        <Breadcrumbs aria-label="Breadcrumb">
          <Link component={NavLink} to={allRoutes.dashboard.path}>
            Dashboard
          </Link>
          <Link component={NavLink} to={allRoutes.privateNetworks.path}>
            {allRoutes.privateNetworks.label}
          </Link>
          <Typography>Search</Typography>
        </Breadcrumbs>
      </PageHeading>
      <ContentWrapper inset>
        <Grid2 container justifyContent="space-between" alignItems="center">
          <Grid2 size="grow">
            <Box paddingY={10}>
              <Grid2 container spacing={8} alignItems="flex-end">
                <Grid2 sx={{ display: { xs: "none", md: "block" } }}>
                  <Typography variant="h4">Your Results</Typography>
                </Grid2>
                <Grid2>
                  <Typography variant="subtitle2" gutterBottom>
                    Returned results
                  </Typography>
                  <Typography variant="h4">
                    <span data-testid="networksTotal" data-testvalue={total}>
                      {total.toLocaleString()}
                    </span>{" "}
                    Customers
                  </Typography>
                </Grid2>
              </Grid2>
            </Box>
          </Grid2>
          <Grid2 sx={{ display: { xs: "none", md: "block" } }}>{renderButtons()}</Grid2>
        </Grid2>
      </ContentWrapper>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <ContentWrapper inset>
          <Divider />
          {renderButtons()}
        </ContentWrapper>
      </Box>
      <Divider />
      <TabContext value={lastPart}>
        <AppBar position="sticky" component="div" classes={appBarStyles}>
          {loading ? (
            <Box width="100%" position="relative" height={4}>
              <LinearProgress />
            </Box>
          ) : (
            <Box width="100%" position="relative" style={{ background: "#fff" }} height={4}></Box>
          )}
          <ContentWrapper body inset>
            <Box paddingTop={3}>
              <Tabs value={lastPart} onChange={handleChange} indicatorColor="primary" textColor="primary" aria-label="icon tabs">
                <Tab
                  label="Table"
                  value="search"
                  data-testid="table-tab"
                  icon={<CalendarViewMonthIcon />}
                  iconPosition="start"
                  style={{
                    paddingLeft: isTablet ? "40px" : "20px",
                    paddingRight: isTablet ? "40px" : "20px",
                  }}
                />
                <Tab
                  label="Pie Chart"
                  value="pie"
                  data-testid="pie-chart-tab"
                  icon={<PieChartIcon />}
                  iconPosition="start"
                  style={{
                    minWidth: "180px",
                    paddingLeft: isTablet ? "40px" : "20px",
                    paddingRight: isTablet ? "40px" : "20px",
                  }}
                />{" "}
                <Tab
                  label="Tree Map"
                  value="tree"
                  data-testid="tree-tab"
                  icon={<PieChartIcon />}
                  iconPosition="start"
                  style={{
                    minWidth: "180px",
                    paddingLeft: isTablet ? "40px" : "20px",
                    paddingRight: isTablet ? "40px" : "20px",
                  }}
                />
                <Tab
                  label="Annual growth"
                  value="area"
                  icon={<BarChartIcon />}
                  iconPosition="start"
                  style={{
                    minWidth: "185px",
                    paddingLeft: isTablet ? "40px" : "20px",
                    paddingRight: isTablet ? "40px" : "20px",
                  }}
                />
              </Tabs>
            </Box>
            <Divider />
          </ContentWrapper>
        </AppBar>
        <ContentWrapper body inset>
          <Box minHeight={500}>
            <TabPanel value="pie">
              {lastPart !== viewCurrent ? (
                <></>
              ) : !loading && !results.length ? (
                <NoResults />
              ) : (
                <NetworksPieChart data={results} type={format} loading={loading} setType={setFormat} />
              )}
            </TabPanel>
            <TabPanel value="tree">
              {lastPart !== viewCurrent ? (
                <></>
              ) : !loading && !results.length ? (
                <NoResults />
              ) : (
                <NetworksTreeChart /* data={results} */ filters={values} loading={loading} />
              )}
            </TabPanel>
            <TabPanel value="area">
              {lastPart !== viewCurrent ? (
                <></>
              ) : !loading && !results.length ? (
                <NoResults />
              ) : (
                <NetworksAreaChart /* data={results} */ filters={values} loading={loading} />
              )}
            </TabPanel>
            <TabPanel value="search">
              {lastPart !== viewCurrent ? (
                <></>
              ) : !loading && !results.length ? (
                <NoResults />
              ) : (
                <TableAdvanced
                  title={`GAMBoD NTS Search - ${total.toLocaleString()} results, page ${page}`}
                  dense={dense}
                  rows={results}
                  cellsMap={networksCells(sortBy, sortDesc, values, true)}
                  page={page}
                  pages={pages}
                  fontSize={fontSize}
                  rowsPerPage={rowsPerPage}
                  loading={loading}
                  setRowsPerPage={value => {
                    dispatch(setRowsPerPage(value));
                  }}
                  setPage={value => {
                    dispatch(setPage(value));
                  }}
                  setFontSize={value => {
                    dispatch(setFontSize(value));
                  }}
                  setDense={value => {
                    dispatch(setDense(value));
                  }}
                  onOrder={value => {
                    dispatch(setSortBy(value));
                  }}
                />
              )}
            </TabPanel>
          </Box>
        </ContentWrapper>
      </TabContext>
    </Box>
  );
};

export default PrivateNetworksSearch;
