import { Button, Grid2, Paper } from "@mui/material";
import React from "react";

const ExternalLinks: React.FC = () => {
  return (
    <Grid2 container paddingTop={5} paddingBottom={1} spacing={4} marginY={-2}>
      <Grid2 paddingBlock={2}>
        <Button
          color="secondary"
          size="small"
          onClick={() => {
            window.open("https://gsacom.com/gambod/spectrum-bands-for-5g/");
          }}
        >
          Band Explanation 5G
        </Button>
      </Grid2>
      <Grid2 paddingBlock={2}>
        <Button
          color="secondary"
          size="small"
          onClick={() => {
            window.open("https://gsacom.com/gambod/spectrum-bands-for-lte/");
          }}
        >
          Band Explanation LTE
        </Button>
      </Grid2>
    </Grid2>
  );
};

export default ExternalLinks;
