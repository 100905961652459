import { SvgIcon } from "@mui/material";

const ChipsetsSvg: React.FC = () => {
  return (
    <SvgIcon sx={{ fontSize: "1.5rem" }} viewBox="0 0 28.55 28.56">
      <path
        d="M19.88,32.58a.72.72,0,0,0,.21.05H31.8a.41.41,0,0,0,.19,0,.44.44,0,0,0,.16-.1l.17-.18,0-.09a.39.39,0,0,0,0-.18V20.32a.45.45,0,0,0,0-.11.54.54,0,0,0-.18-.27.57.57,0,0,0-.19-.1H20.09a.5.5,0,0,0-.48.38.45.45,0,0,0,0,.11V32.14a.38.38,0,0,0,0,.21A.51.51,0,0,0,19.88,32.58Zm.7-11.76H31.42V31.65H20.58Z"
        transform="translate(-11.72 -11.95)"
        fill="#fff"
      />
      <path
        d="M18.13,32.63h0a1.47,1.47,0,0,0-1.45,1.67,1.53,1.53,0,0,0,1.17,1.26,1.48,1.48,0,0,0,1.62-2.1A1.53,1.53,0,0,0,18.13,32.63Zm-.2,1.93a.52.52,0,0,1-.29-.57.5.5,0,0,1,.48-.37h0a.5.5,0,0,1,.48.57A.51.51,0,0,1,17.93,34.56Z"
        transform="translate(-11.72 -11.95)"
        fill="#fff"
      />
      <path
        d="M39.78,26.72a.49.49,0,0,0,0-1H37.32v-2h2.46a.5.5,0,0,0,0-1H37.32v-2h2.49a.5.5,0,0,0,.44-.64.51.51,0,0,0-.44-.34H37.32v-2h2.46a.51.51,0,0,0,.5-.53.5.5,0,0,0-.47-.45H37.32V15.45h0a.55.55,0,0,0-.53-.53H35.35V12.4a.49.49,0,0,0-.43-.44H34.8a.5.5,0,0,0-.43.44v2.51h-2V12.4A.49.49,0,0,0,32,12h-.12a.49.49,0,0,0-.43.44v2.51h-2V12.45a.07.07,0,0,0,0,0A.49.49,0,0,0,29,12h-.12a.5.5,0,0,0-.43.44v2.51h-2V12.4a.49.49,0,0,0-.43-.44h-.12a.5.5,0,0,0-.43.44v2.51h-2V12.4A.51.51,0,0,0,23.1,12H23a.49.49,0,0,0-.43.44.07.07,0,0,0,0,0v2.46h-2V12.4a.49.49,0,0,0-.43-.44H20a.5.5,0,0,0-.43.44v2.51h-2V12.4A.49.49,0,0,0,17.2,12h-.12a.49.49,0,0,0-.43.44v2.51H15.21a.55.55,0,0,0-.53.54v1.43H12.21a.49.49,0,1,0,0,1h2.47v2H12.19a.52.52,0,0,0-.44.67.53.53,0,0,0,.46.32h2.47v2H12.19a.52.52,0,0,0-.44.67.51.51,0,0,0,.46.32h2.47v2H12.19a.51.51,0,0,0-.44.67.5.5,0,0,0,.46.31h2.47v2H12.19a.52.52,0,0,0-.44.67.53.53,0,0,0,.46.32h2.47v2H12.21a.49.49,0,1,0,0,1h2.47v2H12.21a.52.52,0,0,0-.48.39.51.51,0,0,0,.48.59h2.47V37h0a.55.55,0,0,0,.54.53h1.43V40a.5.5,0,0,0,.52.49.52.52,0,0,0,.46-.49V37.55h2V40a.52.52,0,0,0,.67.44.5.5,0,0,0,.31-.46V37.55h2V40a.51.51,0,0,0,.53.49.52.52,0,0,0,.46-.49V37.55h2V40a.49.49,0,0,0,1,0V37.55h2V40a.52.52,0,0,0,.67.44.53.53,0,0,0,.32-.46V37.55h2V40a.5.5,0,0,0,.38.48.52.52,0,0,0,.6-.48V37.55h2V40a.51.51,0,0,0,.53.49.51.51,0,0,0,.45-.49V37.55h1.44a.55.55,0,0,0,.53-.54V35.58h2.49a.5.5,0,0,0,.43-.66.49.49,0,0,0-.43-.32H37.32v-2h2.46a.49.49,0,0,0,0-1H37.32v-2h2.46a.54.54,0,0,0,.46-.3.52.52,0,0,0-.43-.69H37.32v-2Zm-3.44,9.85H15.66V15.89H36.34Z"
        transform="translate(-11.72 -11.95)"
        fill="#fff"
      />
      <path
        d="M17.84,19.8a1.47,1.47,0,1,0,.29-2.92h0a1.47,1.47,0,0,0-1.45,1.68A1.49,1.49,0,0,0,17.84,19.8Zm-.2-1.56a.5.5,0,0,1,.48-.38h0a.49.49,0,1,1-.2.95A.52.52,0,0,1,17.64,18.24Z"
        transform="translate(-11.72 -11.95)"
        fill="#fff"
      />
      <path
        d="M33.6,35.56a1.48,1.48,0,1,0,.29-2.93h0a1.48,1.48,0,0,0-1,.43,1.48,1.48,0,0,0,.77,2.5ZM33.4,34a.5.5,0,0,1,.47-.37h0a.49.49,0,0,1,.47.56.49.49,0,1,1-1-.19Z"
        transform="translate(-11.72 -11.95)"
        fill="#fff"
      />
      <path
        d="M33.6,19.8a1.47,1.47,0,1,0,.29-2.92h0a1.48,1.48,0,0,0-1,.43,1.47,1.47,0,0,0,.77,2.49Zm-.2-1.56a.5.5,0,0,1,.47-.38h0a.49.49,0,1,1-.2.95A.5.5,0,0,1,33.4,18.24Z"
        transform="translate(-11.72 -11.95)"
        fill="#fff"
      />
    </SvgIcon>
  );
};

export default ChipsetsSvg;
