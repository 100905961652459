import { Box, Grid2, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

import Logo from "../LogoSvg";
const now = new Date();
const version = "v1." + (now.getMonth() + 1) + now.getDate() + "." + now.getHours() + now.getMinutes();

const HeaderLogo: React.FC = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box height={isTablet ? 56 : 96} paddingLeft={[4, 8]}>
      <Link href="https://gsacom.com/" target="_blank">
        <Grid2 container component="span" wrap="nowrap" alignItems="flex-end">
          <Grid2 component="span">
            <Box paddingTop={[6, 6, 6, 8]} marginLeft={4} title={version}>
              <Logo size={!isTablet ? "medium" : "small"} />
            </Box>
          </Grid2>
          <Grid2 component="span" sx={{ display: { xs: "none", lg: "block" } }}>
            <Box marginLeft={4}>
              <Typography color="textPrimary" style={{ fontSize: "0.8rem" }}>
                Global mobile
                <br />
                Suppliers Association
              </Typography>
            </Box>
          </Grid2>
        </Grid2>
      </Link>
    </Box>
  );
};

export default HeaderLogo;
