import { Box, Button, FormControl, Grid2, InputLabel, MenuItem, Select } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import FilterListItem from "../FilterListItem";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export interface YearRangeFilterProps {
  title: string;
  name?: string;
  tooltip?: string;
  startDate?: number;
  endDate?: number;
  value: (number | null)[];
  disabled?: boolean;
  open?: boolean;
  setOpen: (open: boolean) => void;
  onChange: (value: (number | null)[]) => void;
}

const YearRangeFilter: React.FC<YearRangeFilterProps> = ({
  title = "Date Range Filter",
  name = "date-range-filter",
  tooltip,
  //startDate = 0,
  //endDate = new Date(new Date().getFullYear()).getTime(),
  value = [null, null],
  disabled,
  open,
  setOpen,
  onChange,
}) => {
  const [fromDate, toDate] = value;

  const classes = useStyles();
  const [startDate, setstartDate] = React.useState(2010);
  const [endDate, setendDate] = React.useState(new Date(new Date().getFullYear()).getTime());
  const handleChange = (event: any) => {
    setstartDate(event.target.value);
    onChange([event.target.value, endDate]);
  };
  const handleChange2 = (event: any) => {
    setendDate(event.target.value);
    onChange([startDate, event.target.value]);
  };

  const generateYears = (startYear: number) => {
    const years = [];
    for (var i = startYear; i <= new Date().getFullYear(); i++) {
      years.push(i);
    }
    return years;
  };

  const yearOptions = generateYears(2010);

  return (
    <FilterListItem title={title} tooltip={tooltip} selected={0} open={open} setOpen={setOpen}>
      <Grid2 container spacing={4}>
        <Grid2 size={{ xs: 6 }}>
          <FormControl variant="filled" className={classes.formControl} style={{ width: "100px" }}>
            <InputLabel id="startYear">Start Year</InputLabel>
            <Select labelId="startYear" id={`${name}-from`} value={startDate} onChange={handleChange}>
              {yearOptions.map(option => (
                <MenuItem key={`m1${option}`} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid2>
        <Grid2 size={{ xs: 6 }}>
          <FormControl variant="filled" className={classes.formControl} style={{ width: "100px" }}>
            <InputLabel id="endYear">End Year</InputLabel>
            <Select labelId="endYear" id={`${name}-to`} value={endDate} onChange={handleChange2}>
              {yearOptions.map(option => (
                <MenuItem key={`m2${option}`} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid2>
      </Grid2>
      <Box paddingTop={4}>
        <Button
          id={`${name}-clear`}
          variant="outlined"
          size="small"
          disabled={disabled || (fromDate === null && toDate === null)}
          onClick={() => {
            onChange([null, null]);
          }}
        >
          Clear
        </Button>
      </Box>
    </FilterListItem>
  );
};

export default YearRangeFilter;
