import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
//import ApexCharts from 'apexcharts'
import ReactApexChart from "react-apexcharts";

export interface AreaChartProps {
  title?: string;
  data: any[];
  cutoutPercentage?: number;
  rotation?: number;
  displayLegend?: boolean;
  loading?: boolean;
  valueSuffix?: string;
  colors: string[];
  setRef?: any; //(value: Doughnut) => void;
}

export type Serie = {
  name: string;
  data: number[];
};

/* const series = [
  {
    name: "Customers",
    data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
  },
]; */
const defaultOptions = {
  chart: {
    height: 560,
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "straight",
  },
  title: {
    text: "Yearly development",
    align: "left",
  },
  grid: {
    row: {
      colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      opacity: 0.5,
    },
  },
  /* xaxis: {
    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
  }, */
};

const AreaChart: React.FC<AreaChartProps> = ({ data = [], setRef }) => {
  const ref = useRef(null);
  const [series, setSeries] = useState<Serie[]>([]);
  const [options, setOptions] = useState<any>(undefined);

  useEffect(() => {
    if (data.length > 0) {
      setSeries([{ name: "Customers", data: data ? data.map(item => item.total) : [] }]);
    }
    const xTitles = data.map(item => item.title);
    setOptions({
      ...defaultOptions,
      xaxis: {
        categories: xTitles,
      },
    });

    if (setRef && ref?.current) {
      setRef(ref.current);
    }
  }, [setRef, data]);

  return (
    <Box maxWidth={1280} width="100%">
      <Box height={[720, 720, 640, 560]} marginY={8} width="100%">
        {data.length > 0 && options?.xaxis ? (
          <ReactApexChart type="line" options={options} color="#ee238c" series={series} height={560} />
        ) : (
          <>Loading</>
        )}
      </Box>
    </Box>
  );
};
export default AreaChart;
