import { Route, Routes } from "react-router-dom";

import { useSelector } from "react-redux";
import AllocationsFilters from "../components/AllocationsForm";
import ChipsetFilters from "../components/ChipsetForm";
import DevicesFilters from "../components/DevicesForm";
import OperatorsFilters from "../components/OperatorsForm";
import PrivateNetworksFilters from "../components/PrivateNetworksForm";
import PublicNetworksFilters from "../components/PublicNetworksForm";
import allRoutes from "../constants/routes";
import Main from "../layouts/Main";
import Allocations from "../pages/Allocations";
import AllocationsSearch from "../pages/AllocationsSearch";
import Chipsets from "../pages/Chipsets";
import ChipsetsDetail from "../pages/ChipsetsDetail";
import ChipsetsSearch from "../pages/ChipsetsSearch";
import Dashboard from "../pages/Dashboard";
import Devices from "../pages/Devices";
import DevicesSearch from "../pages/DevicesSearch";
import Operators from "../pages/Operators";
import OperatorsDetail from "../pages/OperatorsDetail";
import OperatorsSearch from "../pages/OperatorsSearch";
import PrivateNetworks from "../pages/PrivateNetworks";
import PrivateNetworksSearch from "../pages/PrivateNetworksSearch";
import PublicNetworks from "../pages/PublicNetworks";
import PublicNetworksSearch from "../pages/PublicNetworksSearch";
import { selectPermissions } from "../store/slices/auth";
import MySearches from "../pages/MySearches";
import { Box } from "@mui/material";

const DashboardRoutes = () => {
  const permissions = useSelector(selectPermissions);

  const checkPermission = (permissions: string[], permission: any) => {
    const hasPermission = permissions.includes(permission);
    // console.log("permissions", permission, permissions, hasPermission);
    return hasPermission;
  };
  return (
    <Main
      filters={
        <Routes>
          {checkPermission(permissions, "read_gambod_devices") && (
            <Route path={`${allRoutes.devices.path}/*`} element={<DevicesFilters />} />
          )}
          {checkPermission(permissions, "read_gambod_public_networks") && (
            <Route path={`${allRoutes.publicNetworks.path}/*`} element={<PublicNetworksFilters />} />
          )}
          {checkPermission(permissions, "read_gambod_private_networks") && (
            <Route path={`${allRoutes.privateNetworks.path}/*`} element={<PrivateNetworksFilters />} />
          )}
          {checkPermission(permissions, "read_gambod_operators") && (
            <Route path={`${allRoutes.operators.path}/*`} element={<OperatorsFilters />} />
          )}
          {checkPermission(permissions, "read_gambod_chipsets") && (
            <Route path={`${allRoutes.chipsets.path}/*`} element={<ChipsetFilters />} />
          )}
          {checkPermission(permissions, "read_gambod_allocations") && (
            <Route path={`${allRoutes.spectrum_assignment.path}/*`} element={<AllocationsFilters />} />
          )}
          {checkPermission(permissions, "read_gambod_my_searches") && (
            <Route
              path={`${allRoutes.my_searches.path}/*`}
              element={
                <Box paddingY={12} paddingX={16}>
                  <p>GSA is constantly updating the GAMBoD databases with the latest industry data.</p>
                  <p> Search criteria are expanded as new features and technologies or spectrum bands are added.</p>
                  <p>If you have any feedback or suggestions for new areas of data collection please let us know in the feedback form.</p>
                </Box>
              }
            />
          )}
          <Route
            path={`${allRoutes.dashboard.path}/*`}
            element={
              <Box paddingY={12} paddingX={16}>
                <p>Hello there!</p>
                <p>
                  GSA is constantly updating the GAMBoD databases with the latest industry data. Search criteria are expanded as new
                  features and technologies or spectrum bands are added.
                </p>
                <p>If you have any feedback or suggestions for new areas of data collection please let us know in the feedback form.</p>
              </Box>
            }
          />
        </Routes>
      }
    >
      <Routes>
        {checkPermission(permissions, "read_gambod_dashboard") && <Route path={allRoutes.dashboard.path} element={<Dashboard />} />}

        {checkPermission(permissions, "read_gambod_devices") && <Route path={allRoutes.devices.path} element={<Devices />} />}
        {checkPermission(permissions, "read_gambod_devices") && (
          <Route path={`${allRoutes.devices.path}/search/*`} element={<DevicesSearch />} />
        )}

        {checkPermission(permissions, "read_gambod_public_networks") && (
          <Route path={allRoutes.publicNetworks.path} element={<PublicNetworks />} />
        )}
        {checkPermission(permissions, "read_gambod_public_networks") && (
          <Route path={`${allRoutes.publicNetworks.path}/search/:view?`} element={<PublicNetworksSearch />} />
        )}

        {checkPermission(permissions, "read_gambod_private_networks") && (
          <Route path={allRoutes.privateNetworks.path} element={<PrivateNetworks />} />
        )}
        {checkPermission(permissions, "read_gambod_private_networks") && (
          <Route path={`${allRoutes.privateNetworks.path}/search/:view?`} element={<PrivateNetworksSearch />} />
        )}

        {checkPermission(permissions, "read_gambod_operators") && <Route path={allRoutes.operators.path} element={<Operators />} />}
        {checkPermission(permissions, "read_gambod_operators") && (
          <Route path={`${allRoutes.operators.path}/search/:view?`} element={<OperatorsSearch />} />
        )}
        {checkPermission(permissions, "read_gambod_operators") && (
          <Route path={`${allRoutes.operators.path}/operator/:id?`} element={<OperatorsDetail />} />
        )}

        {checkPermission(permissions, "read_gambod_chipsets") && <Route path={allRoutes.chipsets.path} element={<Chipsets />} />}
        {checkPermission(permissions, "read_gambod_chipsets") && (
          <Route path={`${allRoutes.chipsets.path}/search/:view?`} element={<ChipsetsSearch />} />
        )}
        {checkPermission(permissions, "read_gambod_chipsets") && (
          <Route path={`${allRoutes.chipsets.path}/chipset/:id?`} element={<ChipsetsDetail />} />
        )}

        {checkPermission(permissions, "read_gambod_allocations") && (
          <Route path={allRoutes.spectrum_assignment.path} element={<Allocations />} />
        )}
        {checkPermission(permissions, "read_gambod_allocations") && (
          <Route path={`${allRoutes.spectrum_assignment.path}/search/:view?`} element={<AllocationsSearch />} />
        )}

        {checkPermission(permissions, "read_gambod_my_searches") && <Route path={allRoutes.my_searches.path} element={<MySearches />} />}
      </Routes>
    </Main>
  );
};

export default DashboardRoutes;
