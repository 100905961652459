import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { Box, Button, Divider } from "@mui/material";
import { FormatListBulleted, Memory, People, PhoneAndroid, PlaylistAdd, SettingsBackupRestore, Waves } from "@mui/icons-material";
import { isEqual } from "lodash";

import { mapValues, mapValuesToSaveData } from "../../utils";
import { ChipsetType } from "../../types";
import { commercialOptions } from "../../constants";
import allRoutes from "../../constants/routes";
import { selectUiState, setDevicesFilters, setFiltersOpen, closeDevicesFilters } from "../../store/slices/ui";
import { setValues as setDeviceValues, setSavedValues, initialValues, selectDevicesSlice } from "../../store/slices/devices";
import { useSaveSearchMutation } from "../../store/apis/save";
import {
  useGetSupportsQuery,
  useGetChipsetsQuery,
  useGetFallbacksQuery,
  useGetFormFactorsQuery,
  useGetDeviceVendorsQuery,
  useGetFrequencyBandsAllQuery,
  // useGetTechnologiesQuery,
  useGetTechnologiesByNameQuery,
  useGetRedCapsQuery,
} from "../../store/apis/search";

import FilterList from "../FilterList";
import SearchFilter from "../SearchFilter";
import CheckboxFilter from "../CheckboxFilter";
import MonthRangeFilter from "../MonthRangeFilter";
import FrequenciesFilter from "../FrequenciesFilter";
import ExternalLinks from "../ExternalLinks";

const DevicesForm: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { values: valuesCurrent, lastSaved } = useSelector(selectDevicesSlice);
  const { devices: filters } = useSelector(selectUiState);

  const { values, setValues, setFieldValue, handleSubmit, handleReset } = useFormik({
    initialValues,
    onSubmit: values => {
      dispatch(setFiltersOpen(false));
      dispatch(setDeviceValues(values));

      const { path } = allRoutes.devices;

      if (pathname === path) {
        navigate(`${path}/search`);
      }
    },
  });

  //const { data: technologiesData = [], isLoading: technologiesIsLoading } = useGetTechnologiesQuery(1);
  const { data: technologiesData = [], isLoading: technologiesIsLoading } = useGetTechnologiesByNameQuery("device");
  const technologiesOptions = technologiesData.filter(technology => [5, 6].includes(technology.id));
  const { data: redCapsOptions = [], isLoading: redCapsIsLoading } = useGetRedCapsQuery();
  const { data: formFactorsOptions = [], isLoading: formFactorsIsLoading } = useGetFormFactorsQuery();
  const { data: deviceVendorsOptions = [], isLoading: deviceVendorsIsLoading } = useGetDeviceVendorsQuery();
  const { data: frequencyBandsOptions = [[], [], []], isLoading: frequencyBandsIsLoading } = useGetFrequencyBandsAllQuery({
    technologies: values.technologies,
    sectionId: 1,
  });
  const { data: fallbacksOptions = [], isLoading: fallbacksIsLoading } = useGetFallbacksQuery(values.technologies);
  const { data: supportsOptions = [], isLoading: supportsIsLoading } = useGetSupportsQuery(values.technologies);
  const {
    data: chipsetsData = {
      results: [],
    },
    isLoading: chipsetsIsLoading,
  } = useGetChipsetsQuery({
    values: {
      technologies: values.technologies,
      supports: values.supports,
    },
  });
  const { results: chipsetsOptions = [] } = chipsetsData;

  useEffect(() => {
    const fallbacksFiltered = values.fallbacks.filter(option => mapValues(fallbacksOptions).includes(option));

    if (!isEqual(fallbacksFiltered, values.fallbacks)) {
      setFieldValue("fallbacks", fallbacksFiltered);
    }
  }, [fallbacksOptions, values.fallbacks, setFieldValue]);

  useEffect(() => {
    const frequencyBandsFiltered = values.frequencyBands.filter(option => {
      const [options1, options2, options3] = frequencyBandsOptions;

      return mapValues(options1).includes(option) || mapValues(options2).includes(option) || mapValues(options3).includes(option);
    });

    if (!isEqual(frequencyBandsFiltered, values.frequencyBands)) {
      setFieldValue("frequencyBands", frequencyBandsFiltered);
    }
  }, [frequencyBandsOptions, values.frequencyBands, setFieldValue]);

  useEffect(() => {
    const supportsFiltered = values.supports.filter(option => mapValues(supportsOptions).includes(option));

    if (!isEqual(supportsFiltered, values.supports)) {
      setFieldValue("supports", supportsFiltered);
    }
  }, [supportsOptions, values.supports, setFieldValue]);

  useEffect(() => {
    const chipsetsFiltered = values.chipsets.filter(option => mapValues(chipsetsOptions).includes(option));

    if (!isEqual(chipsetsFiltered, values.chipsets)) {
      setFieldValue("chipsets", chipsetsFiltered);
    }
  }, [chipsetsOptions, values.chipsets, setFieldValue]);

  useEffect(() => {
    setValues(valuesCurrent);
  }, [setValues, valuesCurrent]);

  const setFilterOpen = useCallback(
    (key: string, open: boolean) => {
      dispatch(setDevicesFilters({ ...filters, [key]: open }));
    },
    [dispatch, filters],
  );

  const [saveSearch] = useSaveSearchMutation();

  const handleSave = () => {
    dispatch(setSavedValues(valuesCurrent));
    saveSearch({
      sectionId: 1,
      data: mapValuesToSaveData(1, valuesCurrent),
    });
  };

  return (
    <FilterList
      disabled={
        technologiesIsLoading ||
        redCapsIsLoading ||
        formFactorsIsLoading ||
        frequencyBandsIsLoading ||
        deviceVendorsIsLoading ||
        fallbacksIsLoading ||
        supportsIsLoading ||
        chipsetsIsLoading
      }
      canSearch={!isEqual(values, valuesCurrent)}
      canSave={!isEqual(valuesCurrent, lastSaved)}
      onSubmit={handleSubmit}
      onReset={handleReset}
      onSave={handleSave}
    >
      <Box paddingX={8} paddingY={4} textAlign="right" bgcolor="grey.450">
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            dispatch(closeDevicesFilters());
          }}
        >
          Close all
        </Button>
      </Box>
      <Divider />
      <CheckboxFilter
        title="Devices"
        name="technologies-filter"
        tooltip="Select 4G or 5G devices - Other-G when available"
        value={technologiesOptions.filter(option => values.technologies.includes(option.id))}
        icon={<PhoneAndroid fontSize="small" />}
        options={technologiesOptions}
        loading={technologiesIsLoading}
        open={filters.technologies}
        onChange={value => setFieldValue("technologies", mapValues(value))}
        setOpen={open => setFilterOpen("technologies", open)}
      />
      <CheckboxFilter
        title="RedCap"
        name="redcap-filter"
        tooltip="Select RedCap"
        value={redCapsOptions.filter(option => values.redcaps.includes(option.id))}
        icon={<PhoneAndroid fontSize="small" />}
        options={redCapsOptions}
        loading={redCapsIsLoading}
        open={filters.redcaps}
        onChange={value => setFieldValue("redcaps", mapValues(value))}
        setOpen={open => setFilterOpen("redcaps", open)}
      />
      <MonthRangeFilter
        title="Dates"
        name="month-range-filter"
        tooltip="Search by date range. Date is when the device is added to the database, not the availability or launch date."
        value={[values.startDate, values.endDate]}
        open={filters.dates}
        onChange={(value: (number | null)[]) => {
          const [startDate, endDate] = value;

          setFieldValue("startDate", startDate);
          setFieldValue("endDate", endDate);
        }}
        setOpen={open => setFilterOpen("dates", open)}
      />
      <SearchFilter
        title="Vendors"
        name="vendors-filter"
        tooltip="Filter by vendor"
        value={deviceVendorsOptions.filter(option => values.deviceVendors.includes(option.id))}
        icon={<People fontSize="small" />}
        options={deviceVendorsOptions}
        loading={deviceVendorsIsLoading}
        disabled={!deviceVendorsOptions.length}
        open={filters.vendors}
        onChange={value => setFieldValue("deviceVendors", mapValues(value))}
        setOpen={open => setFilterOpen("vendors", open)}
      />
      <SearchFilter
        title="Form Factor"
        name="form-factor-filter"
        tooltip="Filter by type of device"
        value={formFactorsOptions.filter(option => values.formFactors.includes(option.id))}
        icon={<FormatListBulleted fontSize="small" />}
        options={formFactorsOptions}
        loading={formFactorsIsLoading}
        disabled={!formFactorsOptions.length}
        open={filters.formFactors}
        onChange={value => setFieldValue("formFactors", mapValues(value))}
        setOpen={open => setFilterOpen("formFactors", open)}
      />
      <SearchFilter
        title="Fallbacks"
        name="fallbacks-filter"
        tooltip="What the device falls back to if the main technology is not available."
        value={fallbacksOptions.filter(option => values.fallbacks.includes(option.id))}
        icon={<SettingsBackupRestore fontSize="small" />}
        options={fallbacksOptions}
        loading={fallbacksIsLoading}
        disabled={!fallbacksOptions.length}
        open={filters.fallbacks}
        onChange={value => setFieldValue("fallbacks", mapValues(value))}
        setOpen={open => setFilterOpen("fallbacks", open)}
      />
      <FrequenciesFilter
        title="Frequencies"
        name="frequency-bands-filter"
        tooltip="What frequency bands the device supports"
        value={[
          frequencyBandsOptions[0].filter(option => values.frequencyBands.includes(option.id)),
          frequencyBandsOptions[1].filter(option => values.frequencyBands.includes(option.id)),
          frequencyBandsOptions[2].filter(option => values.frequencyBands.includes(option.id)),
        ]}
        icon={<Waves fontSize="small" />}
        options={frequencyBandsOptions}
        loading={frequencyBandsIsLoading}
        disabled={!frequencyBandsOptions.length}
        open={filters.frequencyBands}
        setOr={value => setFieldValue("frequencyBandControl", value)}
        orValue={values.frequencyBandControl}
        onChange={value => {
          setFieldValue("frequencyBands", mapValues(value.flat()));
        }}
        setOpen={open => setFilterOpen("frequencyBands", open)}
      />
      <SearchFilter
        title="Supports"
        name="supports-filter"
        tooltip="Features the device supports"
        value={supportsOptions.filter(option => values.supports.includes(option.id))}
        icon={<PlaylistAdd fontSize="small" />}
        options={supportsOptions}
        loading={supportsIsLoading}
        disabled={!supportsOptions.length}
        open={filters.supports}
        onChange={value => setFieldValue("supports", mapValues(value))}
        setOpen={open => setFilterOpen("supports", open)}
      />
      <SearchFilter
        title="Chipsets"
        name="chipsets-filter"
        tooltip="Where know the chipset vendor and type in the device"
        value={chipsetsOptions.filter((option: ChipsetType) => values.chipsets.includes(option.id))}
        icon={<Memory fontSize="small" />}
        options={chipsetsOptions}
        loading={chipsetsIsLoading}
        disabled={!chipsetsOptions.length || !values.technologies.includes(5)}
        open={filters.chipsets}
        onChange={value => setFieldValue("chipsets", mapValues(value))}
        setOpen={open => setFilterOpen("chipsets", open)}
      />
      <CheckboxFilter
        title="Commercial Status"
        name="commercial-filter"
        tooltip="Use this to toggle the search results between showing all devices catalogued, only those devices known to be commercially available, or only those that are pre-commercial, or unknown - where the commercial status is unknown"
        value={commercialOptions.filter(option => values.commercial.includes(option.value))}
        icon={<PhoneAndroid fontSize="small" />}
        options={commercialOptions}
        open={filters.commercial}
        onChange={value => setFieldValue("commercial", mapValues(value))}
        setOpen={open => setFilterOpen("commercial", open)}
      />
      <Box paddingX={8} paddingY={1}>
        <ExternalLinks />
      </Box>
    </FilterList>
  );
};

export default DevicesForm;
