import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";

import Page404 from "../pages/auth/Page404";
import allRoutes from "../constants/routes";
import DashboardRoutes from "./DashboardRoutes";
import SignIn from "../pages/SignIn";
import PrivateRoute from "../components/PrivateRoute";

import * as Sentry from "@sentry/react";

const SentryRoutes = Sentry.withSentryReactRouterV7Routing(Routes);

const AppRoutes = () => (
  <Router>
    <SentryRoutes>
      <Route path="/" element={<Navigate to={allRoutes.dashboard.path} replace />} />
      <Route
        path="*"
        element={
          <PrivateRoute>
            <DashboardRoutes />
          </PrivateRoute>
        }
      />
      <Route path="/signin" element={<SignIn />} />
      <Route loader={() => <Page404 />} />
    </SentryRoutes>
  </Router>
);

export default AppRoutes;
