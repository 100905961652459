import { SvgIcon } from "@mui/material";

const AllocationsSvg: React.FC = () => {
  return (
    <SvgIcon sx={{ fontSize: "1.5rem" }} viewBox="0 0 31.2 24.2">
      <path
        fill="#fff"
        d="M11.9,24.2c-2.2,0-3.3-4.4-4.2-10.9c-0.1-0.5-0.1-0.9-0.2-1.1c0-0.3-0.1-0.6-0.2-1.1c-1.2-8.6-2.4-9.9-3-9.9
	c0,0,0,0,0,0c-0.6,0-1.8,1.3-3,10.1c0,0.4-0.1,0.7-0.1,0.9c0,0.3-0.4,0.6-0.7,0.5C0.2,12.6,0,12.3,0,12c0-0.2,0.1-0.5,0.1-0.9
	C0.9,5.4,1.9,0,4.3,0c0,0,0,0,0,0c2.2,0,3.3,4.4,4.2,10.9c0.1,0.5,0.1,0.9,0.2,1.1c0,0.2,0.1,0.6,0.2,1.1c1.2,8.6,2.4,9.9,3,9.9
	c0,0,0,0,0,0c0.7,0,1.9-1.7,2.9-9.9c0.1-0.5,0.1-0.9,0.2-1.1c0-0.2,0.1-0.6,0.2-1.1C16,4.4,17.1,0,19.3,0c0,0,0,0,0,0
	c2.2,0,3.3,4.4,4.2,10.9c0.1,0.5,0.1,0.9,0.2,1.1c0,0.3,0.1,0.6,0.2,1.1c1.2,8.6,2.4,9.9,3,9.9c0,0,0,0,0,0c0.6,0,1.8-1.3,3-10.1
	c0-0.4,0.1-0.7,0.1-0.9c0-0.3,0.4-0.5,0.7-0.5c0.3,0,0.6,0.4,0.5,0.7c0,0.2-0.1,0.5-0.1,0.9c-0.7,5.8-1.8,11.1-4.2,11.1c0,0,0,0,0,0
	c-2.2,0-3.3-4.4-4.2-10.9c-0.1-0.5-0.1-0.9-0.2-1.1c0-0.2-0.1-0.6-0.2-1.1c-1.2-8.6-2.4-9.9-3-9.9c0,0,0,0,0,0
	c-0.7,0-1.9,1.7-2.9,9.9c-0.1,0.5-0.1,0.9-0.2,1.1c0,0.2-0.1,0.6-0.2,1.1C15.2,19.8,14.1,24.2,11.9,24.2
	C11.9,24.2,11.9,24.2,11.9,24.2z"
      />
    </SvgIcon>
  );
};

export default AllocationsSvg;
