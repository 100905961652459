import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { Box, Button, Divider } from "@mui/material";
import { Memory, /* Public, */ People, PhoneAndroid, SignalCellularAlt, Update } from "@mui/icons-material";
import { isEqual } from "lodash";

import { mapValues, mapValuesToSaveData } from "../../utils";
import allRoutes from "../../constants/routes";
import { initialValues, setValues as setChipsetsValues, selectChipsetsSlice, setSavedValues } from "../../store/slices/chipsets";
import { setChipsetsFilters, selectUiState, setFiltersOpen, closeChipsetsFilters } from "../../store/slices/ui";
import {
  useGetSupportsQuery,
  useGetChipsetVendorsQuery,
  useGetChipsetTypesQuery,
  useGetUeCategoriesQuery,
  // useGetTechnologiesQuery,
  useGetTechnologiesByNameQuery,
  // useGetRegionsQuery,
} from "../../store/apis/search";
// import { selectSaveToken } from "../../store/slices/auth";
import { useSaveSearchMutation } from "../../store/apis/save";
import FilterList from "../FilterList";
import SearchFilter from "../SearchFilter";
import CheckboxFilter from "../CheckboxFilter";
import { commercialOptions } from "../../constants";

const ChipsetForm: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { values: valuesCurrent, lastSaved } = useSelector(selectChipsetsSlice);
  const { chipsets: filters } = useSelector(selectUiState);
  // const token = useSelector(selectSaveToken);

  const { values, setFieldValue, setValues, handleSubmit, handleReset } = useFormik({
    initialValues,
    onSubmit: (values: any) => {
      dispatch(setFiltersOpen(false));
      dispatch(setChipsetsValues(values));

      const { path } = allRoutes.chipsets;

      if (pathname !== `${path}/search`) {
        navigate(`${path}/search`);
      }
    },
  });

  //const { data: technologiesOptions = [], isLoading: isTechnologiesLoading } = useGetTechnologiesQuery(4);
  // const { data: regionsOptions = [], isLoading: regionsLoading } = useGetRegionsQuery();
  const { data: technologiesOptions = [], isLoading: isTechnologiesLoading } = useGetTechnologiesByNameQuery("chipset");
  const { data: chipsetVendorsOptions = [], isLoading: chipsetVendorsIsLoading } = useGetChipsetVendorsQuery();
  const { data: chipsetTypesOptions = [], isLoading: chipsetTypesIsLoading } = useGetChipsetTypesQuery();
  const { data: supportsOptions = [], isLoading: supportsIsLoading } = useGetSupportsQuery([]);
  const { data: ueOptions = [], isLoading: ueIsLoading } = useGetUeCategoriesQuery();

  useEffect(() => {
    const supportsFiltered = values.supports.filter((option: any) => mapValues(supportsOptions).includes(option));

    if (!isEqual(supportsFiltered, values.supports)) {
      setFieldValue("supports", supportsFiltered);
    }
  }, [supportsOptions, values.supports, setFieldValue]);

  useEffect(() => {
    setValues(valuesCurrent);
  }, [setValues, valuesCurrent]);

  const setFilterOpen = useCallback(
    (key: string, value: boolean) => {
      dispatch(setChipsetsFilters({ ...filters, [key]: value }));
    },
    [dispatch, filters],
  );

  const [saveSearch] = useSaveSearchMutation();

  const handleSave = () => {
    dispatch(setSavedValues(valuesCurrent));
    saveSearch({
      sectionId: 5,
      data: mapValuesToSaveData(5, valuesCurrent),
    });
  };

  return (
    <FilterList
      disabled={supportsIsLoading || chipsetVendorsIsLoading || chipsetTypesIsLoading}
      canSearch={!isEqual(values, valuesCurrent)}
      canSave={!isEqual(valuesCurrent, lastSaved)}
      onSubmit={handleSubmit}
      onReset={handleReset}
      onSave={handleSave}
    >
      <Box paddingX={4} paddingY={2} textAlign="right" bgcolor="grey.450">
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            dispatch(closeChipsetsFilters());
          }}
        >
          Close all
        </Button>
      </Box>
      <Divider />
      {/*  <SearchFilter
        title="Main Regions"
        name="operators-regions"
        tooltip="Filter the search by ITU Region"
        value={regionsOptions.filter(option => values.regions.includes(option.id))}
        icon={<Public fontSize="small" />}
        options={regionsOptions}
        loading={regionsLoading}
        disabled={!regionsOptions.length}
        open={filters.regions}
        onChange={value => {
          //dispatch(setSubRegions(value.map(item => item.id)));
          setFieldValue("regions", mapValues(value));
        }}
        setOpen={value => setFilterOpen("regions", value)}
      /> */}
      <SearchFilter
        title="Chipset Vendors"
        name="chipset-vendors-filter"
        tooltip="Refine your search by vendor."
        value={chipsetVendorsOptions.filter(option => values.chipsetVendors.includes(option.id))}
        icon={<People fontSize="small" />}
        disabled={!chipsetVendorsOptions.length}
        options={chipsetVendorsOptions}
        loading={chipsetVendorsIsLoading}
        open={filters.chipsetVendors}
        onChange={value => setFieldValue("chipsetVendors", mapValues(value))}
        setOpen={value => setFilterOpen("chipsetVendors", value)}
      />
      <SearchFilter
        title="Chipset Types"
        name="chipset-types-filter"
        tooltip="Refine your search by chipset type"
        value={chipsetTypesOptions.filter(option => values.chipsetTypes.includes(option.id))}
        icon={<Memory fontSize="small" />}
        disabled={!chipsetTypesOptions.length}
        options={chipsetTypesOptions}
        loading={chipsetTypesIsLoading}
        open={filters.chipsetTypes}
        onChange={value => setFieldValue("chipsetTypes", mapValues(value))}
        setOpen={value => setFilterOpen("chipsetTypes", value)}
      />
      <CheckboxFilter
        title="Networks"
        tooltip="What network type is supported"
        value={technologiesOptions.filter(option => values.technologies.includes(option.id))}
        icon={<SignalCellularAlt fontSize="small" />}
        options={technologiesOptions}
        loading={isTechnologiesLoading}
        open={filters.technologies}
        onChange={value => setFieldValue("technologies", mapValues(value))}
        setOpen={value => setFilterOpen("technologies", value)}
      />
      <SearchFilter
        title="Supports"
        name="supports-filter"
        tooltip="What network type is supported"
        value={supportsOptions.filter(option => values.supports.includes(option.id))}
        icon={<Update fontSize="small" />}
        options={supportsOptions}
        loading={supportsIsLoading}
        disabled={!supportsOptions.length}
        open={filters.supports}
        onChange={value => setFieldValue("supports", mapValues(value))}
        setOpen={value => setFilterOpen("supports", value)}
      />
      <SearchFilter
        title="UE Category"
        name="ue-category-filter"
        tooltip="The User Equipment type supported by the chipset"
        value={ueOptions.filter(option => values.ue.includes(option.id))}
        icon={<Update fontSize="small" />}
        options={ueOptions}
        loading={ueIsLoading}
        disabled={!ueOptions.length}
        open={filters.ue}
        onChange={value => setFieldValue("ue", mapValues(value))}
        setOpen={value => setFilterOpen("ue", value)}
      />
      <CheckboxFilter
        title="Commercial Status"
        name="commercial-filter"
        tooltip="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        value={commercialOptions.filter(option => values.commercial.includes(option.value))}
        icon={<PhoneAndroid fontSize="small" />}
        options={commercialOptions}
        open={filters.commercial}
        onChange={value => setFieldValue("commercial", mapValues(value))}
        setOpen={open => setFilterOpen("commercial", open)}
      />
    </FilterList>
  );
};

export default ChipsetForm;
