import React from "react";
import { Box, Grid2, Typography, useTheme, Paper } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { AllocationType } from "../../types";
import AllocationsBarItem from "./AllocationsBarItem";
import { colors } from "../../constants";
import AllocationsBarLegendsItem from "./AllocationsBarLegendsItem";

interface AllocationsBarProps {
  data: AllocationsListItemCellType[];
  isFuture?: boolean;
  current?: number;
  min: number;
  max: number;
  startIndex?: number;
  setCurrent: (current: number) => void;
}

export interface AllocationsListItemCellType {
  start: number;
  end: number;
  title: string;
  label?: React.ReactNode;
  data?: AllocationType;
}

const AllocationsBar: React.FC<AllocationsBarProps> = ({ data, isFuture, current = 0, startIndex = 0, min, max, setCurrent }) => {
  const theme = useTheme();
  const useLeftStyles = makeStyles(() => ({
    root: {
      display: "flex",
      alignItems: "center",
      flexBasis: 80,
      flexShrink: 0,
      flexGrow: 0,
      backgroundColor: "#f6f6f6",
      borderRight: "1px solid #cccccc",
      padding: theme.spacing(2),
    },
  }));
  const leftStyles = useLeftStyles();
  const total = max - min;
  const bgColor = !isFuture ? "#d7ecf3" : "#f6f6f6";

  let legends = data
    .filter(cell => cell.data?.operators?.length === 1)
    .map(cell => cell.title)
    .reduce<string[]>((a, b) => (a.includes(b) ? a : [...a, b]), []);

  return (
    <>
      <Box bgcolor={bgColor} border="1px solid #cccccc" width="100%">
        <Grid2 container alignItems="stretch" wrap="nowrap">
          <Paper classes={leftStyles}>
            <Typography>{isFuture ? "Future" : "Current"}</Typography>
          </Paper>
          <Grid2 size="grow">
            <Box width="100%" position="relative" flexGrow={1} flexShrink={1} height={56} overflow="hidden">
              {data.map((cell, index) => {
                const { start, end, title } = cell;
                const colorFindIndex = legends.findIndex(legend => legend === title);

                // console.log(JSON.stringify(cell.data));
                let assigned = false;
                let operatorDefined = false;
                if (cell.data) {
                  assigned = cell.data.assigned === "Y";
                }
                if (cell.data?.operators) {
                  operatorDefined = cell.data.operators.length > 0;
                }

                let color = !!cell.data
                  ? cell.data.assigned !== "Y"
                    ? "#acccdc"
                    : colors[colorFindIndex > -1 ? colorFindIndex : legends.length + startIndex + index]
                  : bgColor;

                if (!operatorDefined && assigned) {
                  color = "#c7a46b";
                  // Add the Assigned to the legends only once and if it is valid to do so.
                  if (legends.indexOf("Assigned") === -1) {
                    legends.push("Assigned");
                  }
                }

                const left = ((start - min) / total) * 100;
                const width = ((end - start) / total) * 100;
                const text = !!cell.data
                  ? `${cell.data.band_start} MHz - ${cell.data.band_end} MHz ${
                      cell.data.operators?.length ? cell.data.operators.map(operator => operator.title).join(", ") : ""
                    }`
                  : `${cell.start} MHz - ${cell.end} MHz No Data`;

                return (
                  <AllocationsBarItem
                    key={index}
                    title={text}
                    width={width}
                    left={left}
                    color={color}
                    isFuture={isFuture}
                    /* active={current === startIndex + index} */
                    active={false}
                    onClick={() => {
                      setCurrent(startIndex + index);
                    }}
                  />
                );
              })}
            </Box>
          </Grid2>
        </Grid2>
      </Box>
      <Box overflow="auto" width="100%">
        <Box paddingY={4} paddingX={2}>
          <Grid2 container wrap="nowrap" spacing={4}>
            <AllocationsBarLegendsItem key={0} color={!isFuture ? "#d3ecf3" : "#ffffff"} value="No Data" />
            <AllocationsBarLegendsItem key={1} color="#acccdc" value="Unassigned" />
            {legends.map((legend, index) => {
              return <AllocationsBarLegendsItem key={index + 2} color={colors[index]} value={legend} />;
            })}
          </Grid2>
        </Box>
      </Box>
    </>
  );
};

export default AllocationsBar;
