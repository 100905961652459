import React, { useCallback, useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Checkbox, Collapse, Divider, IconButton, ListItemButton, ListItemIcon, ListItem, ListItemText } from "@mui/material";

import { ParentType } from "../../types";
import CheckboxFilterItemChild from "./CheckboxFilterItemChild";

export interface CheckboxFilterItemProps {
  option: ParentType;
  options: ParentType[];
  value?: ParentType[];
  disabled?: boolean;
  last?: boolean;
  onChange: (value: ParentType[]) => void;
}

const CheckboxFilterItem: React.FC<CheckboxFilterItemProps> = ({ option, options = [], value = [], disabled, last, onChange }) => {
  const [open, setOpen] = useState(true);

  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleChange = useCallback(
    (checked: boolean, option: ParentType) => {
      onChange(checked ? [...value, option] : value.filter(item => item.id !== option.id));
    },
    [value, onChange],
  );

  return (
    <>
      <ListItemButton dense disabled={disabled} onClick={() => handleChange(!value.includes(option), option)}>
        <ListItemIcon>
          <Checkbox
            name={String(option.id)}
            color="primary"
            checked={value.includes(option)}
            tabIndex={-1}
            onChange={(_, checked) => handleChange(checked, option)}
          />
        </ListItemIcon>
        <ListItemText primary={option.title} />
        {options.length > 0 && (
          <ListItem secondaryAction sx={{ justifyContent: "flex-end", paddingRight: 6 }}>
            <IconButton edge="end" onClick={() => handleClick()} size="large" sx={{ padding: 0 }}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </ListItem>
        )}
      </ListItemButton>
      {options.length > 0 && (
        <Collapse in={open}>
          {options.map(child => (
            <CheckboxFilterItemChild key={child.id} option={child} value={value} onChange={handleChange} disabled={disabled} />
          ))}
        </Collapse>
      )}
      {!last && <Divider />}
    </>
  );
};

export default CheckboxFilterItem;
