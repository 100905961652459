import { CssBaseline, Theme, ThemeProvider } from "@mui/material";
import { Helmet, HelmetProvider } from "react-helmet-async";

import AppRoutes from "./routes";
import { lightVariant } from "./theme/variants";

import * as Sentry from "@sentry/react";
import React from "react";
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";

const isLocal = window?.location?.href?.startsWith("http://localhost");
if (!isLocal) {
  Sentry.init({
    dsn: "https://e5b9872d6c822dd2eb9e502d07a8843a@o4506671555870720.ingest.sentry.io/4506671931981824",
    integrations: [
      Sentry.reactRouterV7BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //tracePropagationTargets: ["localhost", /^https:\/\/gsacom\.com/],
    tracePropagationTargets: [/^https:\/\/gsacom\.com/],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

const App = () => {
  return (
    <HelmetProvider>
      <Helmet defaultTitle="GSA GAMBoD" />
      <ThemeProvider theme={lightVariant}>
        <CssBaseline />
        <AppRoutes />
      </ThemeProvider>
    </HelmetProvider>
  );
};
export default App;
