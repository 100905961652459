import React, { useEffect, useState, useCallback } from "react";
import { Box /* , Button, Collapse, Grid, TextField */ } from "@mui/material";

import FilterSlider from "../FilterSlider";
import FilterListItem from "../FilterListItem";

export interface SpeedRangeFilterProps {
  title: string;
  secondary?: string;
  name?: string;
  tooltip?: string;
  min: number;
  max: number;
  value: number[];
  marks?: number[];
  current?: boolean;
  active?: boolean;
  disabled?: boolean;
  loading?: boolean;
  open?: boolean;
  setOpen: (open: boolean) => void;
  onChange: (value: number[]) => void;
  onFocus?: () => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
}

const SpeedRangeFilter: React.FC<SpeedRangeFilterProps> = ({
  title,
  secondary,
  value,
  tooltip,
  min,
  max,
  name = "speed-range",
  current,
  active,
  marks = [],
  disabled,
  loading,
  open,
  setOpen,
  onChange,
  onFocus,
  onMouseOver,
  onMouseOut,
}) => {
  // const [inputsOpen, setInputsOpen] = useState(false);
  const [valueCurrent, setValue] = useState(value);
  const [from, to] = valueCurrent;

  const getValue = (value: number) => `${value >= 1000 ? value / 1000 : value} ${value < 1000 ? "MHz" : "GHz"}`;

  // const convertValue = useCallback((value: string) => Math.min(Math.max(parseFloat(value) * 1000, min), max), [min, max]);

  const marksArr = [min, ...marks, max].map(mark => ({
    value: mark,
    label: getValue(mark),
  }));

  useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <FilterListItem
      title={title}
      secondary={secondary}
      tooltip={tooltip}
      open={open}
      selected={active ? `${Math.floor(value[0] / 100) / 10} - ${Math.floor(value[1] / 100) / 10}` : 0}
      setOpen={setOpen}
    >
      <Box
        style={{ opacity: active ? 1 : 0.5, padding: "17px 15px 0" }}
        onFocus={onFocus}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        <FilterSlider
          value={[from, to]}
          marks={marksArr}
          min={min}
          max={max}
          name={`${name}-range`}
          disabled={disabled || loading}
          onChange={(value: number | number[]) => {
            if (Array.isArray(value)) {
              setValue(value);
            }
          }}
          onBlur={() => {
            onChange(valueCurrent);
          }}
        />
        {/* <Collapse in={inputsOpen}>
          <Box paddingY={4}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <TextField
                  label="From"
                  type="number"
                  name={`${name}-from`}
                  InputProps={{
                    inputProps: {
                      min: Math.floor(from / 10) / 100,
                      max: Math.floor(max / 10) / 100,
                      step: 0.01,
                    },
                  }}
                  value={Math.floor(from / 10) / 100}
                  disabled={disabled || loading}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setValue([convertValue(event.target.value), to]);
                  }}
                  onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                    onChange([convertValue(event.target.value), to]);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="To"
                  type="number"
                  name={`${name}-to`}
                  InputProps={{
                    inputProps: {
                      min: Math.floor(from / 10) / 100,
                      max: Math.floor(max / 10) / 100,
                      step: 0.01,
                    },
                  }}
                  value={Math.floor(to / 10) / 100}
                  disabled={disabled || loading}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setValue([from, convertValue(event.target.value)]);
                  }}
                  onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                    onChange([from, convertValue(event.target.value)]);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Collapse> */}
      </Box>
      {/*  <Box paddingTop={4}>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              size="small"
              disabled={loading || disabled}
              onClick={() => {
                onChange([min, max]);
              }}
            >
              Reset
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              size="small"
              disabled={loading || disabled}
              onClick={() => {
                setInputsOpen(value => !value);
              }}
            >
              Pick frequency range
            </Button>
          </Grid>
        </Grid>
      </Box> */}
    </FilterListItem>
  );
};

export default SpeedRangeFilter;
