import { AppBar, Box, List, ListItemButton, Typography, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import allRoutes, { RouteProps } from "../../constants/routes";
import { selectPermissions } from "../../store/slices/auth";

const SearchNav: React.FC = () => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const useListStyles = makeStyles(() => ({
    root: {
      padding: 0,
      display: "flex",
      backgroundColor: "#888a8e",
      alignItems: "flex-end",
      [theme.breakpoints.up("lg")]: {
        justifyContent: "flex-end",
      },
    },
  }));

  const appBarStyles = makeStyles(() => ({
    positionSticky: {
      top: 0,
    },
  }));

  const useListItemButtonStyles = makeStyles(() => ({
    root: {
      paddingLeft: 0,
      paddingRight: 0,
      height: 52,
      flexGrow: "0 !important",
      justifyContent: "center",

      "&.Mui-selected": {
        backgroundColor: `${theme.palette.gunmetal[350]} !important`,
        color: theme.palette.tertiary,
      },
      [theme.breakpoints.up("md")]: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
        width: "auto",
      },
    },
  }));
  const listItemButtonStyles = useListItemButtonStyles();
  const listStyles = useListStyles();

  const path = `/${pathname.split("/")[1]}`;
  const menu: RouteProps[] = Object.values(allRoutes).map((route: RouteProps) => route);

  const permissions = useSelector(selectPermissions);

  const checkPermissionToDisplay = (permission: string) => {
    return permissions.includes(permission);
  };

  return (
    <AppBar position="sticky" component="div" classes={appBarStyles()}>
      <Box bgcolor="gunmetal.300" color="rgba(255, 255, 255, 0.95)" width="100%">
        <List classes={listStyles} component="div">
          {menu.map(
            (route, index) =>
              checkPermissionToDisplay(route.permission) && (
                <ListItemButton key={index} to={route.path} component={Link} selected={route.path === path} classes={listItemButtonStyles}>
                  <Typography>{route.label}</Typography>
                </ListItemButton>
              ),
          )}
        </List>
      </Box>
    </AppBar>
  );
};

export default SearchNav;
