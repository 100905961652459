import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect } from "react";
import { useGetTechnologiesByNameQuery } from "../../store/apis/search";
import FilterListItem from "../FilterListItem";

export interface TechFilterProps {
  title: string;
  name?: string;
  tooltip?: string;
  value: number[];
  placeholder?: string;
  open?: boolean;
  loading?: boolean;
  icon?: React.ReactNode;
  setOpen: (value: boolean) => void;
  onChange: (value: any[]) => void;
  onChangeSub: (value: any[]) => void;
}

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const TechFilter: React.FC<TechFilterProps> = ({
  title = "techs",
  name = "tech-filter",
  tooltip,
  value = [],
  open,
  icon,
  setOpen,
  onChange,
  onChangeSub,
}) => {
  const classes = useStyles();

  const { data: techsOptions = [], isLoading: isTechLoading } = useGetTechnologiesByNameQuery("public_network");

  const [techValue, settechValue] = React.useState(0);
  const [subTechValue, setsubTechValue] = React.useState(0);

  useEffect(() => {
    let foundTech = techsOptions.find(option => option.id === value[0]);
    const foundTechId = foundTech?.parent_id === null ? foundTech?.id : (foundTech?.parent_id ?? 0);
    settechValue(foundTechId);
    // if its subtech
    if (foundTech?.id !== value[0]) {
      setsubTechValue(value[0] ?? 0);
    }
  }, [techsOptions, value]);

  const handleChange = (event: any) => {
    settechValue(event.target.value);
    setsubTechValue(0);
    onChange([event.target.value]);
  };

  const handleChange2 = (event: any) => {
    setsubTechValue(event.target.value);
    onChangeSub([event.target.value]);
  };
  const handleReset = (event: any) => {
    settechValue(0);
    setsubTechValue(0);
    onChange([]);
    onChangeSub([]);
  };

  const listSubtechs = () => {
    const selectedTech = techsOptions.find(option => option.id === techValue);
    const subtechs = techsOptions?.filter(option => option.parent_title === selectedTech?.title);
    return subtechs ?? [];
  };

  return (
    <>
      <FilterListItem title={title} icon={icon} open={open} tooltip={tooltip} setOpen={setOpen} selected={value.flat().length}>
        {/* {value[0]} / {techValue} / {subTechValue} */}
        <Box marginBottom={4} id="tech-filter">
          <FormControl variant="filled" className={classes.formControl} disabled={isTechLoading} fullWidth>
            <InputLabel id="tech">Tech</InputLabel>
            <Select labelId="tech" id={`${name}-parent`} value={techValue} onChange={handleChange} data-testid="techsDropdown">
              <MenuItem key={`m1-`} value={""}>
                ---
              </MenuItem>
              {techsOptions
                .filter(option => option.parent_id === null)
                .map(option => (
                  <MenuItem key={`tech${option.id}`} value={option.id}>
                    {option.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {value[0] && listSubtechs()?.length > 0 && (
            <>
              <FormControl variant="filled" className={classes.formControl} fullWidth style={{ margin: "10px 0px 10px 0px" }}>
                <InputLabel id="subtech">Subtech</InputLabel>
                <Select labelId="subtech" id={`${name}-parent`} value={subTechValue} onChange={handleChange2}>
                  <MenuItem key={`m2-`} value={""}>
                    ---
                  </MenuItem>
                  {listSubtechs()?.map(option => (
                    <MenuItem key={`subtech${option.id}`} value={option.id}>
                      {option.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
          <Grid container spacing={2} marginTop={5}>
            <Grid item>
              <Button id={`${name}-clear`} variant="outlined" size="small" onClick={handleReset}>
                Clear
              </Button>
            </Grid>
          </Grid>
        </Box>
      </FilterListItem>
    </>
  );
};

export default TechFilter;
