import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppStateType } from "../";
import { RegionType } from "../../types";
import { NetworksValuesType } from "../../utils";

export const initialValues: NetworksValuesType = {
  //operator_network_id: null,
  technologies: [],
  subTechnologies: [],
  features: [],
  customer_status: [],
  usage: [],
  statuses: [],
  featuresControl: true,
  ituRegions: [],
  regions: [],
  childRegions: [],
  countries: [],
  frequencyBands: [],
  frequencyBandControl: true,
  ue: [],
  downloadSpeed: 1600,
  downloadSpeedControl: "all",
  publicStatus: 1, // not needed?
  startDate: null,
  endDate: null,
  spectrumBands: [],
  satVendors: [],
  satelitentn: [],
  switchoffTechs: null,
};

const initialRegion: RegionType = {
  parent_id: 0,
  parent_title: "",
  title: "",
  id: 0,
};

const networksSlice = createSlice({
  name: "networks",
  initialState: {
    values: initialValues,
    lastSaved: initialValues,
    rowsPerPage: 20,
    page: 1,
    sortBy: "operator_title",
    sortDesc: false,
    lastPage: "blank",
    allSubRegions: [initialRegion], // Array of RegionType
    filteredSubRegions: [initialRegion], // Array of RegionType
  },
  reducers: {
    setRowsPerPage: (state, { payload }: PayloadAction<number>) => {
      state.rowsPerPage = payload;
      state.page = 1;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setValues: (state, { payload }: PayloadAction<NetworksValuesType>) => {
      state.values = payload;
      state.page = 1;
    },
    setLastPage: (state, { payload }: PayloadAction<string>) => {
      state.lastPage = payload;
      state.page = 1;
    },
    setSavedValues: (state, { payload }: PayloadAction<NetworksValuesType>) => {
      state.lastSaved = payload;
    },
    setAllSubRegions: (state, { payload }: PayloadAction<RegionType[]>) => {
      //  console.log(payload);
      state.allSubRegions = payload;
    },
    setSubRegions: (state, { payload }: PayloadAction<number[]>) => {
      // console.log(payload);
      // console.log(state.allSubRegions.filter(region => payload.indexOf(region.parent_id) === -1));
      state.filteredSubRegions =
        payload.length === 0 ? state.allSubRegions : state.allSubRegions.filter(region => payload.indexOf(region.parent_id) !== -1); //region.parent_id === payload);
    },
    resetValues: state => {
      state.values = initialValues;
      state.page = 1;
    },
    setSortBy: (state, { payload }: PayloadAction<string>) => {
      if (state.sortBy.valueOf() !== payload) {
        state.sortBy = payload;
        state.sortDesc = false;
        state.page = 1;
      } else {
        state.sortDesc = !state.sortDesc.valueOf();
      }
    },
  },
});

export const { setRowsPerPage, setPage, setValues, resetValues, setSortBy, setSavedValues, setLastPage, setSubRegions, setAllSubRegions } =
  networksSlice.actions;

export const selectNetworksSlice = (state: AppStateType) => state.networks;

export default networksSlice.reducer;
