import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppStateType } from "..";
import { DevicesValuesType } from "../../utils";

export const initialValues: DevicesValuesType = {
  technologies: [5, 6],
  redcaps: [],
  formFactors: [],
  fallbacks: [],
  deviceVendors: [],
  frequencyBands: [],
  frequencyBandControl: true,
  commercial: [0, 1, 2], // tick all three options by default in DEvices -> Commercial Status
  chipsets: [],
  supports: [],
  startDate: null,
  endDate: null,
};

const devicesSlice = createSlice({
  name: "devices",
  initialState: {
    lastSaved: initialValues,
    values: initialValues,
    rowsPerPage: 20,
    page: 1,
    sortBy: "device_vendor",
    sortDesc: false,
  },
  reducers: {
    setRowsPerPage: (state, { payload }: PayloadAction<number>) => {
      state.rowsPerPage = payload;
      state.page = 1;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setValues: (state, { payload }: PayloadAction<DevicesValuesType>) => {
      state.values = payload;
      state.page = 1;
    },
    setSavedValues: (state, { payload }: PayloadAction<DevicesValuesType>) => {
      state.lastSaved = payload;
    },
    resetValues: state => {
      state.values = initialValues;
      state.page = 1;
    },
    setSortBy: (state, { payload }: PayloadAction<string>) => {
      if (state.sortBy.valueOf() !== payload) {
        state.sortBy = payload;
        state.sortDesc = false;
        state.page = 1;
      } else {
        state.sortDesc = !state.sortDesc.valueOf();
      }
    },
  },
});

export const { setRowsPerPage, setPage, setValues, setSortBy, resetValues, setSavedValues } = devicesSlice.actions;

export const selectDevicesSlice = (state: AppStateType) => state.devices;

export default devicesSlice.reducer;
