import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Box, Breadcrumbs, Link, Typography, LinearProgress } from "@mui/material";

import allRoutes from "../constants/routes";
import { useGetChipsetQuery } from "../store/apis/search";
import ContentWrapper from "../components/ContentWrapper";
import FilterNav from "../components/FilterNav";
import DetailTableVertical, { DetailTableVerticalRowType } from "../components/DetailTableVertical";
import PageHeading from "../components/PageHeading";

const ChipsetsDetail: React.FC = () => {
  const { id } = useParams();
  const { data, isLoading, isFetching } = useGetChipsetQuery(id || "");

  const mainData: DetailTableVerticalRowType = {
    headings: [],
    rows: [],
  };
  const speedData: DetailTableVerticalRowType = {
    headings: [],
    rows: [],
  };

  if (!id) {
    return <Navigate to={`${allRoutes.chipsets.path}/search`} />;
  }

  if (data) {
    mainData.headings.push("Vendor", "Chipset", "Model", "Supports", "Technologies");
    mainData.rows.push([
      data.chipset_vendor,
      data.chipset_type,
      data.model,
      data.supports.map(value => value.title).join(", "),
      data.technologies.map(value => value.title).join(", "),
    ]);

    if (data?.speeds?.length) {
      const row2: string[] = [];

      data.speeds.forEach(value => {
        speedData.headings.push(value.speed_type_title);
        row2.push(value.title);
      });

      speedData.rows.push(row2);
    }
  }

  return (
    <>
      <Helmet title="Chipsets - GSA GAMBoD" />
      <FilterNav />
      <PageHeading
        title={data?.model ? data.model : "\u00A0"}
        icon={allRoutes.chipsets.icon}
        color={allRoutes.chipsets.color}
        section="chipsets"
      >
        <Breadcrumbs aria-label="Breadcrumb">
          <Link href={allRoutes.dashboard.path}>Dashboard</Link>
          <Link href={allRoutes.chipsets.path}>Chipsets</Link>
          <Typography>{data?.model ? data.model : "\u00A0"}</Typography>
        </Breadcrumbs>
      </PageHeading>
      {(isLoading || isFetching) && (
        <Box width="100%" position="relative" height={4}>
          <LinearProgress />
        </Box>
      )}
      <ContentWrapper body inset>
        {data && <DetailTableVertical data={mainData} />}
        {data && speedData.rows.length > 0 && <DetailTableVertical title="Speeds" data={speedData} />}
      </ContentWrapper>
    </>
  );
};

export default ChipsetsDetail;
