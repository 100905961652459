import { SvgIcon } from "@mui/material";

const GambodSvg: React.FC = () => {
  return (
    <SvgIcon sx={{ fontSize: "1.4rem" }} viewBox="0 0 27.75 27.75">
      <path
        d="M7.88,19.38a2.5,2.5,0,1,0,2.5,2.5A2.5,2.5,0,0,0,7.88,19.38Zm0,3.5a1,1,0,1,1,1-1A1,1,0,0,1,7.88,22.88Z"
        transform="translate(-0.13 -0.12)"
        fill="#ffffff"
      />
      <path
        d="M13.12,12.37H2.62a2.51,2.51,0,0,0-2.5,2.5V25.38a2.51,2.51,0,0,0,2.5,2.5h10.5a2.51,2.51,0,0,0,2.5-2.5V14.87A2.51,2.51,0,0,0,13.12,12.37Zm1,13a1,1,0,0,1-1,1H2.62a1,1,0,0,1-1-1V14.87a1,1,0,0,1,1-1h10.5a1,1,0,0,1,1,1Z"
        transform="translate(-0.13 -0.12)"
        fill="#ffffff"
      />
      <path
        d="M7.88,15.88a5.16,5.16,0,0,0-3.63,1.5.75.75,0,1,0,1.06,1.06,3.64,3.64,0,0,1,2.57-1.06,3.59,3.59,0,0,1,2.56,1.06.75.75,0,0,0,.53.22.79.79,0,0,0,.53-.22.75.75,0,0,0,0-1.06A5.09,5.09,0,0,0,7.88,15.88Z"
        transform="translate(-0.13 -0.12)"
        fill="#ffffff"
      />
      <path
        d="M25.38.12H20.12a2.51,2.51,0,0,0-2.5,2.5V14.87a2.52,2.52,0,0,0,2.5,2.51h5.26a2.52,2.52,0,0,0,2.5-2.51V2.62A2.51,2.51,0,0,0,25.38.12Zm-5.26,1.5h5.26a1,1,0,0,1,1,1v9.75H19.12V2.62A1,1,0,0,1,20.12,1.62Zm5.26,14.26H20.12a1,1,0,0,1-1-1v-1h7.26v1A1,1,0,0,1,25.38,15.88Z"
        transform="translate(-0.13 -0.12)"
        fill="#ffffff"
      />
      <path
        d="M2.82,9.09a.77.77,0,0,0,0,1.07.75.75,0,0,0,1.06,0,5.36,5.36,0,0,1,7.6,0,.77.77,0,0,0,.53.21.76.76,0,0,0,.53-.21.75.75,0,0,0,0-1.07,6.88,6.88,0,0,0-9.72,0Z"
        transform="translate(-0.13 -0.12)"
        fill="#ffffff"
      />
      <path
        d="M1.41,7.68A8.57,8.57,0,0,1,4.28,5.76,8.9,8.9,0,0,1,14,7.68a.71.71,0,0,0,.53.22A.74.74,0,0,0,15,7.68a.75.75,0,0,0,0-1.06A10.38,10.38,0,0,0,3.71,4.37,10.48,10.48,0,0,0,.34,6.62a.75.75,0,0,0,0,1.06A.77.77,0,0,0,1.41,7.68Z"
        transform="translate(-0.13 -0.12)"
        fill="#ffffff"
      />
    </SvgIcon>
  );
};

export default GambodSvg;
