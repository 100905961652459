import React from "react";
import { Box, Grid2, Typography } from "@mui/material";

export interface AllocationsBarLegendsItemProps {
  value: string;
  color: string;
}

const AllocationsBarLegendsItem: React.FC<AllocationsBarLegendsItemProps> = ({ value, color }) => (
  <Grid2>
    <Grid2 container alignItems="center" wrap="nowrap" spacing={2}>
      <Grid2>
        <Box width={12} height={12} bgcolor={color} border="1px solid #cccccc" />
      </Grid2>
      <Grid2>
        <Typography noWrap>{value}</Typography>
      </Grid2>
    </Grid2>
  </Grid2>
);

export default AllocationsBarLegendsItem;
