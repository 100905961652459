import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid2, ThemeProvider, useTheme, useMediaQuery, Box, IconButton, Typography, Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Close } from "@mui/icons-material";

import Footer from "../components/Footer";
import SearchNav from "../components/SearchNav";
import Sidebar from "../components/Sidebar";
import HeaderLogo from "../components/HeaderLogo";
import { variants } from "../theme/variants";
import HeaderNav from "../components/HeaderNav";
import MobileNav from "../components/MobileNav";
import { selectUiState, setFiltersOpen } from "../store/slices/ui";
import GambodSvg from "../icons/GambodSvg";

interface MainProps {
  filters?: any;
  children?: React.ReactNode;
}

const Main: React.FC<MainProps> = ({ filters, children }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down("lg"));
  const { filtersOpen } = useSelector(selectUiState);

  const useLeftStyles = makeStyles(() => ({
    root: {
      flexBasis: 0,
      //display: "none",
      [theme.breakpoints.up("lg")]: {
        flexBasis: 340,
        flexShrink: 0,
        display: "block",
      },
    },
  }));

  const useRightStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      // width: "100%",
      minWidth: 0,
      backgroundColor: "white!important",
      [theme.breakpoints.up("md")]: {
        width: "auto",
      },
    },
  }));

  const useBottomStyles = makeStyles(() => ({
    root: {
      height: "calc(100% - 180px)",
      [theme.breakpoints.up("lg")]: {
        height: "calc(100% - 150px)",
      },
    },
  }));
  const rightStyles = useRightStyles();
  const bottomStyles = useBottomStyles();
  const leftStyles = useLeftStyles();

  return (
    <>
      <Grid2 container style={{ minHeight: "100vh" }} wrap="nowrap">
        <ThemeProvider theme={variants.DARK}>
          <Paper elevation={0} classes={leftStyles}>
            <Sidebar open={filtersOpen}>
              <Grid2 container direction="column" style={{ height: "100%" }}>
                <Grid2>
                  <Box bgcolor="gunmetal.500">
                    <Grid2 container alignItems="center" justifyContent="space-between">
                      <Grid2>
                        <HeaderLogo />
                      </Grid2>
                      <Grid2 sx={{ display: { xs: "block", lg: "none" } }}>
                        <Box paddingX={2}>
                          <IconButton
                            onClick={() => {
                              dispatch(setFiltersOpen(false));
                            }}
                          >
                            <Close />
                          </IconButton>
                        </Box>
                      </Grid2>
                    </Grid2>
                  </Box>
                </Grid2>
                <Grid2>
                  <Box bgcolor="gunmetal.400" height={52} paddingX={10} display="flex" alignContent="center" justifyContent="flex-start">
                    <Grid2 container alignItems="center" spacing={6}>
                      <Grid2>
                        <GambodSvg />
                      </Grid2>
                      <Grid2>
                        <Typography variant="h5" style={{ color: "rgba(255, 255, 255, 0.95)" }}>
                          GAMBoD
                        </Typography>
                      </Grid2>
                    </Grid2>
                  </Box>
                </Grid2>
                {filters && <Paper classes={bottomStyles}>{filters}</Paper>}
              </Grid2>
            </Sidebar>
          </Paper>
        </ThemeProvider>
        <Paper classes={rightStyles}>
          <HeaderNav />
          {!tablet && <SearchNav />}
          {children}
          <Footer />
        </Paper>
      </Grid2>
      <MobileNav />
    </>
  );
};

export default Main;
