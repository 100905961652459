import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Divider, Grid2, useMediaQuery, useTheme } from "@mui/material";

import {
  useGetDeviceVendorsQuery,
  useGetDevices4gTotalQuery,
  useGetDevices5gTotalQuery,
  useGetChipsetsQuery,
  useGetAllocationsTotalQuery,
} from "../../store/apis/search";
import { getOperators, selectOperatorsTotal } from "../../store/slices/operators";
import DashboardMetricsItem from "./DashboardMetricsItem";

const DashboardMetrics: React.FC = () => {
  const dispatch = useDispatch<any>();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("lg"));
  const operatorsTotal = useSelector(selectOperatorsTotal);
  const deviceVendors = useGetDeviceVendorsQuery();
  const chipsets = useGetChipsetsQuery({ rowsPerPage: 1 });
  const vendorsTotal = deviceVendors.data?.length || 0;
  const chipsetsTotal = chipsets.data?.total || 0;
  const { data: allocationsTotal = 0 } = useGetAllocationsTotalQuery();
  const { data: devices4gTotal = 0 } = useGetDevices4gTotalQuery();
  const { data: devices5gTotal = 0 } = useGetDevices5gTotalQuery();

  useEffect(() => {
    dispatch(getOperators());
  }, [dispatch]);

  useEffect(() => {
    sessionStorage.setItem("devices5gTotal", devices5gTotal + "");
    sessionStorage.setItem("devices4gTotal", devices4gTotal + "");
    sessionStorage.setItem("operatorsTotal", operatorsTotal + "");
    sessionStorage.setItem("vendorsTotal", vendorsTotal + "");
    sessionStorage.setItem("chipsetsTotal", chipsetsTotal + "");
    sessionStorage.setItem("allocationsTotal", allocationsTotal + "");
  }, [devices5gTotal, devices4gTotal, operatorsTotal, vendorsTotal, chipsetsTotal, allocationsTotal]);

  return (
    <Grid2 container alignItems="flex-end">
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <Box paddingY={4}>
          <Grid2 container alignItems="flex-end">
            <Grid2 size={{ xs: 4 }}>
              <DashboardMetricsItem title="5G Devices" value={devices5gTotal} />
            </Grid2>
            <Grid2 size={{ xs: 4 }}>
              <DashboardMetricsItem title="4G Devices" value={devices4gTotal} />
            </Grid2>
            <Grid2 size={{ xs: 4 }}>
              <DashboardMetricsItem title="Operators" value={operatorsTotal} />
            </Grid2>
          </Grid2>
        </Box>
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        {mobile && <Divider />}
        <Box paddingY={4}>
          <Grid2 container alignItems="flex-end">
            <Grid2 size={{ xs: 4 }}>
              <DashboardMetricsItem title="Vendors" value={vendorsTotal} />
            </Grid2>
            <Grid2 size={{ xs: 4 }}>
              <DashboardMetricsItem title="Chipsets" value={chipsetsTotal} />
            </Grid2>
            <Grid2 size={{ xs: 4 }}>
              <DashboardMetricsItem title="Spectrum Assignment" value={allocationsTotal} />
            </Grid2>
          </Grid2>
        </Box>
      </Grid2>
    </Grid2>
  );
};

export default DashboardMetrics;
