import React from "react";
import { Box, Button, Grid2, Radio, RadioGroup, FormControlLabel, TextField } from "@mui/material";

import { OptionType } from "../../types";
import FilterListItem from "../FilterListItem";
import FilterAutocomplete from "../FilterAutocomplete";

export interface SearchFilterProps {
  title: string;
  name?: string;
  tooltip?: string;
  placeholder?: string;
  options: OptionType[];
  value: OptionType[];
  icon?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  limitTags?: number;
  orValue?: boolean;
  open?: boolean;
  setOpen: (value: boolean) => void;
  setOr?: (value: boolean) => void;
  noAutocomplete?: boolean;
  onChange: (value: OptionType[]) => void;
}

const SearchFilter: React.FC<SearchFilterProps> = ({
  title = "Search Filter",
  name = "search-filter",
  placeholder = "Search",
  tooltip,
  icon,
  options = [],
  value = [],
  disabled,
  loading,
  limitTags = 8,
  open,
  orValue,
  setOpen,
  setOr,
  noAutocomplete,
  onChange,
}) => (
  <FilterListItem title={title} tooltip={tooltip} icon={icon} selected={value.length} open={open} setOpen={setOpen}>
    {noAutocomplete ? (
      <Box padding={4} paddingTop={0}>
        <TextField
          id="filled-basic"
          label="Search by name"
          variant="outlined"
          size="small"
          name={`${name}-search`}
          style={{ width: "100%" }}
          defaultValue={value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            //@ts-ignore
            onChange(event.target.value);
          }}
        />
      </Box>
    ) : (
      <FilterAutocomplete
        name={`${name}-search`}
        placeholder={loading ? "Loading..." : placeholder}
        value={value}
        limitTags={limitTags}
        options={options}
        onChange={onChange}
        disabled={disabled || loading}
      />
    )}

    {setOr && (
      <Box padding={4} paddingTop={0}>
        <RadioGroup
          name="control"
          value={orValue ? 1 : 0}
          onChange={(_, value) => {
            setOr(!!parseInt(value));
          }}
        >
          <FormControlLabel value={0} disabled={disabled} control={<Radio color="primary" />} label="Control AND" />
          <FormControlLabel value={1} disabled={disabled} control={<Radio color="primary" />} label="Control OR" />
        </RadioGroup>
      </Box>
    )}
    {!noAutocomplete && (
      <Grid2 container spacing={2}>
        <Grid2>
          <Button
            id={`${name}-clear`}
            variant="outlined"
            size="small"
            disabled={disabled || loading || value.length === 0}
            onClick={() => {
              onChange([]);
            }}
          >
            Clear
          </Button>
        </Grid2>

        <Grid2>
          <Button
            id={`${name}-select-all`}
            variant="outlined"
            size="small"
            disabled={disabled || loading || value.length === options.length}
            onClick={() => {
              onChange(options);
            }}
          >
            Select all
          </Button>
        </Grid2>
      </Grid2>
    )}
  </FilterListItem>
);

export default SearchFilter;
