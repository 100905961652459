import React, { useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid2,
  useMediaQuery,
  useTheme,
  Switch as MuiSwitch,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Add, Print, Remove } from "@mui/icons-material";
import { isEqual } from "lodash";
import { selectUiState, setExportFields } from "../../store/slices/ui";
import Table, { TableCell } from "../Table";
import TablePagination from "../TablePagination";
import { useDispatch, useSelector } from "react-redux";

export interface TableAdvancedProps {
  title?: string;
  cellsMap: TableCell[];
  rows: any[];
  rowsPerPage: number;
  pages: number;
  page: number;
  fontSize?: number;
  maxWidth?: number | string;
  dense: boolean;
  loading: boolean;
  setDense: (value: boolean) => void;
  setRowsPerPage: (value: number) => void;
  setPage: (value: number) => void;
  setFontSize: (value: number) => void;
  onOrder?: (value: string) => void;
}

const print = (title: string) => {
  const element = document.getElementById("table-div");
  if (!element) return;

  // Open a new window with proper parameters
  const printWindow = window.open("", "_blank", "width=800,height=600");
  if (!printWindow) return;

  const printContent = `
      <!DOCTYPE html>
      <html>
        <head>
          <title>${title}</title>
          <style>
            body {
              margin: 2cm;
              font-family: Arial, Helvetica, sans-serif;
            }
            table, td, th {
              border: 1px solid #cccccc;
            }
            table {
              border-collapse: collapse;
              width: 100%;
              margin-top: 1em;
            }
            table td, table th {
              padding: 8px;
              text-align: left;
              font-size: 12px;
            }
            table td, table td * {
              vertical-align: top;
            }
            svg, button, .MuiButtonBase-root {
              display: none !important;
            }
            @media print {
              h3 { margin-top: 0; }
            }
          </style>
        </head>
        <body>
          <h3>${title}</h3>
          ${element.innerHTML}
        </body>
      </html>
    `;

  printWindow.document.write(printContent);
  printWindow.document.close();

  // Wait for content to load before printing
  printWindow.onload = () => {
    printWindow.focus();
    printWindow.print();
    printWindow.onafterprint = () => {
      printWindow.close();
    };
  };
};

const TableAdvanced: React.FC<TableAdvancedProps> = ({
  title = "Table",
  cellsMap,
  rows = [],
  rowsPerPage,
  pages,
  page,
  dense = false,
  fontSize = 1,
  maxWidth = "none",
  setPage,
  setFontSize,
  setDense,
  setRowsPerPage,
  onOrder = () => null,
}) => {
  const dispatch = useDispatch();
  const { exportFields } = useSelector(selectUiState);
  const [cells2, setCells2] = useState(cellsMap);
  const [cells, setCells] = useState(cells2.map(cell => cell.id));
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down("lg"));
  const userFormGroupStyles = makeStyles(() => ({
    root: {
      [theme.breakpoints.down("sm")]: {
        flexWrap: "nowrap",
      },
    },
  }));

  const useLabelStyles = makeStyles(() => ({
    label: {
      whiteSpace: "nowrap",
    },
  }));
  const formGroupStyles = userFormGroupStyles();
  const labelStyles = useLabelStyles();

  useEffect(() => {
    if (!isEqual(cellsMap, cells2)) {
      setCells(
        cellsMap
          .map(cell => cell.id)
          .filter(cell => cells.includes(cell) || (cellsMap.some(c => cell === c.id) && !cells2.some(c => cell === c.id))),
      );
      setCells2(cellsMap);
    }
  }, [cells2, cellsMap, cells]);

  useEffect(() => {
    // console.log(exportFields, cells, JSON.stringify(exportFields) !== JSON.stringify(cells))
    if (JSON.stringify(exportFields) !== JSON.stringify(cells)) {
      dispatch(setExportFields(cells));
    }
  }, [cells, dispatch, exportFields]);

  useEffect(() => {
    // console.log(exportFields, cells, JSON.stringify(exportFields) !== JSON.stringify(cells))
    if (JSON.stringify(exportFields) !== JSON.stringify(cells)) {
      dispatch(setExportFields(cells));
    }
  }, [cells, dispatch, exportFields]);

  const handleUpdateCells = (id: string, checked: boolean) => {
    setCells(checked ? [...cells, id] : cells.filter(cell => cell !== id));
  };

  const fontMinus = () => {
    setFontSize(Math.max(fontSize - 1, 0));
  };

  const fontPlus = () => {
    setFontSize(Math.min(fontSize + 1, 3));
  };

  const handlePrint = () => print(title);

  return (
    <>
      {cells2.length > 3 && (
        <>
          <Box overflow="auto">
            <Box paddingY={4}>
              <FormGroup row classes={formGroupStyles}>
                {cells2.map(cell => (
                  <FormControlLabel
                    classes={labelStyles}
                    key={cell.id}
                    control={
                      <Checkbox
                        name={cell.id}
                        color="primary"
                        onChange={event => {
                          handleUpdateCells(cell.id, event.target.checked);
                        }}
                      />
                    }
                    label={cell.label}
                    checked={cells.includes(cell.id)}
                  />
                ))}
              </FormGroup>
            </Box>
          </Box>
          <Divider />
        </>
      )}
      {/* {JSON.stringify(exportFields)} */}
      <TablePagination page={page} pages={pages} rowsPerPage={rowsPerPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />
      <Divider />
      <Box paddingTop={4}>
        <Table
          id="table-div"
          maxWidth={maxWidth}
          cellsMap={cells2.filter(cell => cells.includes(cell.id))}
          dense={tablet || dense}
          rows={rows}
          onOrder={onOrder}
          fontSize={fontSize}
        />
      </Box>
      <TablePagination page={page} pages={pages} rowsPerPage={rowsPerPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />
      <Divider />
      <Box paddingY={4}>
        <Grid2 container alignItems="center" justifyContent="space-between" wrap="nowrap">
          <Grid2>
            <Grid2 container spacing={8} alignItems="center" wrap="nowrap">
              <Grid2>
                <Grid2 container spacing={2} alignItems="center" wrap="nowrap">
                  <Grid2>
                    <IconButton onClick={fontMinus} disabled={fontSize === 0} size="large">
                      <Remove />
                    </IconButton>
                  </Grid2>
                  <Grid2>
                    <IconButton onClick={fontPlus} disabled={fontSize === 3} size="large">
                      <Add />
                    </IconButton>
                  </Grid2>
                  <Grid2>
                    <Typography>Font size</Typography>
                  </Grid2>
                </Grid2>
              </Grid2>
              {!tablet && (
                <Grid2>
                  <FormControlLabel
                    classes={labelStyles}
                    label="Dense padding"
                    control={
                      <MuiSwitch
                        checked={dense}
                        color="primary"
                        onChange={(_, checked) => {
                          setDense(checked);
                        }}
                      />
                    }
                  />
                </Grid2>
              )}
            </Grid2>
          </Grid2>
          <Grid2>
            <IconButton onClick={handlePrint} size="large">
              <Print />
            </IconButton>
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
};

export default TableAdvanced;
