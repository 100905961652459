import React from "react";
import { Divider, ListItemButton, ListItemIcon, ListItemText, Radio } from "@mui/material";

import { ParentType } from "../../types";

export interface RadioFilterItemProps {
  option: ParentType;
  value?: ParentType;
  disabled?: boolean;
  last?: boolean;
  onChange: (value: ParentType) => void;
}

const RadioFilterItem: React.FC<RadioFilterItemProps> = ({ option, value, disabled, last, onChange }) => {
  return (
    <>
      <ListItemButton dense disabled={disabled} onClick={() => onChange(option)}>
        <ListItemIcon>
          <Radio
            name={String(option.id)}
            color="primary"
            checked={value?.id === option.id}
            tabIndex={-1}
            onChange={value => onChange(option)}
          />
        </ListItemIcon>
        <ListItemText primary={option.title} />
      </ListItemButton>
      {!last && <Divider />}
    </>
  );
};

export default RadioFilterItem;
