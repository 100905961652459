import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Cookies } from "react-cookie";
import { AppStateType } from "..";
import { ssoUrl } from "../../constants/api";
import { getRequest, ssoLoginFromCookieRequest, ssoLoginRequest } from "../../services/apiService";
//import { ApiResponseType } from "../../types";
import { SignInFromCookieType, SignInType } from "../../types/auth";

export interface AuthDataType {
  token: undefined;
  user: undefined;
}

export interface SignInReturnType {
  data?: AuthStateType;
  error?: string;
}

export const signIn = createAsyncThunk<SignInReturnType, SignInType>("auth/signIn", async (body, { dispatch }) => {
  const authenticateResponse = await ssoLoginRequest(`${ssoUrl}login`, body);

  if (authenticateResponse.status !== 200) {
    return { error: authenticateResponse.data.message };
  }

  const user = {
    cookie: authenticateResponse.data.data.cookie,
    display_name: authenticateResponse.data.data.user.DisplayName,
    email: authenticateResponse.data.data.user.Email,
    username: authenticateResponse.data.data.user.Username,
    permissions: authenticateResponse.data.data.user.Permissions,
  };

  const data = {
    user,
    token: authenticateResponse.data.data.token,
    cookie: authenticateResponse.data.data.cookie,
    cookieName: authenticateResponse.data.data.cookieName,
  };

  dispatch(setApiToken(authenticateResponse.data.data.token));
  localStorage.setItem("gambod", JSON.stringify(data));

  const profile = await getRequest("profile");
  if (profile?.allowed?.export) {
    dispatch(setExport(profile.allowed.export));
    localStorage.setItem("export", JSON.stringify(profile.allowed.export));
  }

  return {
    data,
  };
});

export const signInFromCookie = createAsyncThunk<SignInReturnType, SignInFromCookieType>("auth/signIn", async (body, { dispatch }) => {
  const authenticateResponse = await ssoLoginFromCookieRequest(`${ssoUrl}loginFromCookie`, body);

  if (authenticateResponse.status !== 200) {
    return { error: authenticateResponse.data.message };
  }

  const user = {
    cookie: authenticateResponse.data.data.cookie,
    display_name: authenticateResponse.data.data.user.DisplayName,
    email: authenticateResponse.data.data.user.Email,
    username: authenticateResponse.data.data.user.Username,
    permissions: authenticateResponse.data.data.user.Permissions,
  };

  const data = {
    user,
    token: authenticateResponse.data.data.token,
    cookie: authenticateResponse.data.data.cookie,
    cookieName: authenticateResponse.data.data.cookieName,
  };

  dispatch(setApiToken(authenticateResponse.data.data.token));
  localStorage.setItem("gambod", JSON.stringify(data));

  const profile = await getRequest("profile");
  if (profile?.allowed?.export) {
    dispatch(setExport(profile.allowed.export));
    localStorage.setItem("export", JSON.stringify(profile.allowed.export));
  }

  return {
    data,
  };
});

export const getApiToken = createAsyncThunk<void, string>("auth/getApiToken", async (email, { dispatch }) => {
  dispatch(setApiToken(undefined));
  // removed for messing up with member's accounts authorisation

  /*   const apiResponse: ApiResponseType = await getRequest(`get-token?email=${email}`);

  if (apiResponse.token) {
    try {
      const gambod = localStorage.getItem("gambod");

      if (gambod) {
        const data = JSON.parse(gambod);

        localStorage.setItem(
          "gambod",
          JSON.stringify({
            user: data.user,
            token: apiResponse.token,
          })
        );
        dispatch(setApiToken(apiResponse.token));
        dispatch(setApiToken(undefined));
      }
    } catch (error) {
      dispatch(setApiToken(undefined));
    }
  } else {
    dispatch(setApiToken(undefined));
  } */
});

export interface UserType {
  cookie: string;
  display_name: string;
  email: string;
  username: string;
  permissions: string[];
}

export interface AuthStateType {
  token?: string;
  user?: UserType | undefined;
  cookie?: string;
  export?: boolean;
}

const initialState: AuthStateType = {
  token: undefined,
  user: undefined,
  export: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    onSignIn: (state, { payload: { user, token } }: PayloadAction<{ user: UserType; token: string }>) => {
      state.user = user;
      state.token = token;
    },
    signOut: state => {
      const cookies = new Cookies();
      for (var i in cookies.getAll()) {
        if (i.startsWith("wordpress_logged_in_")) {
          cookies.remove(i, {
            domain: process.env.REACT_APP_SSO_COOKIE_DOMAIN,
          });
        }
      }
      localStorage.removeItem("gambod");
      state.user = undefined;
      state.token = undefined;
      document.cookie = "GSA_LOGIN_KEY=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      document.cookie = "GSA_LOGIN=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },
    setApiToken: (state, { payload }: PayloadAction<string | undefined>) => {
      state.token = payload;
    },
    setExport: (state, { payload }: PayloadAction<boolean>) => {
      state.export = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(signIn.fulfilled, (state, { payload }: PayloadAction<SignInReturnType>) => {
      if (payload.data) {
        state = payload.data;
      }
    });
  },
});

export const { onSignIn, signOut, setApiToken, setExport } = authSlice.actions;

export const selectAuthSlice = (state: AppStateType) => state.auth;

export const selectUserDisplayName = createSelector(selectAuthSlice, state => state.user?.display_name);

export const selectSaveToken = createSelector(selectAuthSlice, state => state.token);

export const selectUsername = createSelector(selectAuthSlice, state => state.user?.email);

export const selectUser = createSelector(selectAuthSlice, state => state.user);

export const selectPermissions = createSelector(selectAuthSlice, state => state?.user?.permissions || []);

export const selectExport = createSelector(selectAuthSlice, state => state?.export);

export default authSlice.reducer;
