import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { onSignIn, selectUser, setExport } from "../../store/slices/auth";

type Props = {
  children: React.ReactNode;
};

const PrivateRoute: React.FC<Props> = ({ children }) => {
  const dispatch = useDispatch();
  const gambod = localStorage.getItem("gambod");
  const exportEnabled = localStorage.getItem("export");
  const user = useSelector(selectUser);
  useEffect(() => {
    if (gambod) {
      const data = JSON.parse(gambod);
      dispatch(
        onSignIn({
          token: data.token,
          user: data.user,
        }),
      );
    }
  }, [dispatch, gambod]);

  useEffect(() => {
    if (exportEnabled) {
      try {
        dispatch(setExport(JSON.parse(exportEnabled)));
      } catch (error) {
        console.error("Error parsing export", error);
      }
    }
  }, [dispatch, exportEnabled]);

  if (!gambod) {
    return <Navigate to="/signin" />;
  }

  return <>{user && children}</>;
};

export default PrivateRoute;
